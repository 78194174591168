import React from "react";
import { RentalsListWrapper } from "./rentals-list/RentalsList";



const RentalPayment = () => {

return(


    <>

<h2> Rent Collection List</h2>

            <div className="card p-6 mt-5">
            <RentalsListWrapper />
            </div>

    </>
)




}

export {RentalPayment}