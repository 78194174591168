import React from "react";



const Contacts = () => {

return(


    <>
    </>
)




}

export {Contacts}