import react from "react";
import { RequestsListWrapper } from "./requests-list/RequestsList";





const RequestCallBackListing = () => {

    return (

        <>

            <h1> Request List</h1>
            <div className="card p-6 mt-5">
                <RequestsListWrapper />
            </div>
        </>
    )

}

export { RequestCallBackListing }