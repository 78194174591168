import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ComplaintsListHeader} from './components/header/ComplaintsListHeader'
import {ComplaintsTable} from './table/ComplaintsTable'
import {ComplaintEditModal} from './complaint-edit-modal/ComplaintEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const ComplaintsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ComplaintsListHeader />
        <ComplaintsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ComplaintEditModal />}
    </>
  )
}

const ComplaintsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ComplaintsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ComplaintsListWrapper}
