import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import React  from "react";
import { initialTenant, Tenant } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { TenantsListLoading } from '../components/loading/TenantsListLoading'
import { createTenant, updateTenant } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import ImageUploading from 'react-images-uploading';

type Props = {
  isTenantLoading: boolean
  tenant: Tenant
}


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },


];

const unit_list = [
  { value: ' ', label: 'Select Unit No' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];


const editTenantSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const TenantEditModalForm: FC<Props> = ({ tenant, isTenantLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [tenantForEdit] = useState<Tenant>({
    ...tenant,
    avatar: tenant.avatar || initialTenant.avatar,
    role: tenant.role || initialTenant.role,
    position: tenant.position || initialTenant.position,
    name: tenant.name || initialTenant.name,
    email: tenant.email || initialTenant.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };


  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);

  return (
    <>
      <form id='kt_modal_add_tenant_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_tenant_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_tenant_header'
          data-kt-scroll-wrappers='#kt_modal_add_tenant_scroll'
          data-kt-scroll-offset='300px'
        >

          <h3 className=' px-0 py-3 mb-8' > Personal Details </h3>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3 " >
                <Form.Label>Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="Text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Email address <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="email" className='form-control form-control-solid' placeholder=" " required />
              </Form.Group>
            </Col>

          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Mobile No <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="tel" className='form-control form-control-solid' placeholder=" " required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type="date" className='form-control form-control-solid' placeholder=" "  />
              </Form.Group>
            </Col>
          </Row>


          <Row className='mb-8 tenent-img'>
          <Col sm={12} md={6}>
          <div className="App image upload section">
              <Form.Label> Upload Photo </Form.Label>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                    className="py-3 px-3 fs-4"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
                    <div className="d-flex flex-wrap">
                      {imageList.map((image, index) => (

                        <div key={index} className="image-item my-10 mx-5">
                          <img src={image['data_url']} alt="" style={{maxWidth:"100%"}} />
                          <div className="image-item__btn-wrapper text-center">
                            <button onClick={() => onImageUpdate(index)} className="me-2 px-1 update bg-light-orange text-orange"> Update </button>
                            <button onClick={() => onImageRemove(index)} className="px-1 remove"> X </button>
                          </div>
                        </div>

                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading>

            </div>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Tenant Info <span className='fs-6 text-danger'> * </span></Form.Label>
                <Row>
                  <Col className='d-inline-block'>
                    <Form.Check // prettier-ignore
                      type="radio"
                      id="individual"
                      label="Individual"
                      name="group1"
                      className='d-inline-block me-10'
                  
                    />
                    <Form.Check
                      type="radio"
                      label="Family"
                      id="family"
                      name="group1"
                      className='d-inline-block'
                      
                    />
                  </Col>

                </Row>
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-7'></div>

          <h3 className=' px-0 py-3 mb-8' > Property Details </h3>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Select Property <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Select Unit <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={unit_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Status</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Active"
                checked
              />
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <TenantsListLoading />} */}
    </>
  )
}

export { TenantEditModalForm }
