import {FC} from 'react'

type Props = {
  two_steps?: boolean
}

const ComplaintTwoStepsCell: FC<Props> = ({two_steps}) => (
  <> {
    // two_steps && 
    <div className=' fw-bolder'>
      <select className="form-select" aria-label="Default select example">
  <option value="1">Pending</option>
  <option value="2">In Progress</option>
  <option value="3">On Hold</option>
  <option value="3">Completed</option>
</select>
    </div>
    }</>
)

export {ComplaintTwoStepsCell}
