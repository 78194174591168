const QUERIES = {
  USERS_LIST: 'users-list',
  TENANTS_LIST: 'tenants-list',
  PROPERTYMANAGEMENTS_LIST: 'propertymanagements-list',
  UNITS_LIST: 'units-list',
  FLOORS_LIST: 'floors-list',
  RENTALS_LIST: 'rentals-list',
  COMPLAINTS_LIST: 'complaints-list',
  REQUESTS_LIST: 'requests-list',
  
}

export {QUERIES}
