import {useListView} from '../../core/ListViewProvider'
import {RentalsListToolbar} from './RentalListToolbar'
import {RentalsListGrouping} from './RentalsListGrouping'
import {RentalsListSearchComponent} from './RentalsListSearchComponent'

const RentalsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <RentalsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <RentalsListGrouping /> : <RentalsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {RentalsListHeader}
