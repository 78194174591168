// @ts-nocheck
import {Column} from 'react-table'
import {RentalInfoCell} from './RentalInfoCell'
import {RentalLastLoginCell} from './RentalLastLoginCell'
import {RentalTwoStepsCell} from './RentalTwoStepsCell'
import {RentalActionsCell} from './RentalActionsCell'
import {RentalSelectionCell} from './RentalSelectionCell'
import {RentalCustomHeader} from './RentalCustomHeader'
import {RentalSelectionHeader} from './RentalSelectionHeader'
import {Rental} from '../../core/_models'

const rentalsColumns: ReadonlyArray<Column<Rental>> = [
 
  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Invoice' className='min-w-125px ' />,
    accessor: 'invoice',
    Cell: ({...props}) => "001"
  },

  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Tenant Name' className='min-w-125px ' />,
    accessor: 'tenant_name',
    Cell: ({...props}) => "Mr. Raj Jangid"
  },

  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Unit No' className='min-w-125px' />,
    accessor: 'unit_no',
    Cell: ({...props}) => "D/501"
  },

  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Rent Month' className='min-w-125px ' />,
    accessor: 'rent_month',
    Cell: ({...props}) => "February"
  },

  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Rent Year' className='min-w-125px' />,
    accessor: 'rent_year',
    Cell: ({...props}) => "2024"
  },

  {
    Header: (props) => <RentalCustomHeader tableProps={props} title='Total Rent' className='min-w-125px' />,
    accessor: 'total_rent',
    Cell: ({...props}) => "QAR 13500"
  },

  {
    Header: (props) => (
      <RentalCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <RentalTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },

  
  {
    Header: (props) => (
      <RentalCustomHeader tableProps={props} title='Actions' className='text-end min-w-150px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RentalActionsCell id={props.data[props.row.index].id} />,
  },
]

export {rentalsColumns}
