import React from "react";



const Configurations = () => {

return(


    <>
    </>
)




}

export {Configurations}