import React from "react";
import { Col, Row } from "react-bootstrap";
import { TenantsListWrapper } from "./tenants-list/TenantsList";
import { TenantsListHeader } from "./tenants-list/components/header/TenantsListHeader";



const TenantsDetails = () => {

    return (

        <>

            <h2> Tenants Details</h2>

            <div className="card p-6 mt-5">
                <TenantsListWrapper />
            </div>
        </>
    )




}

export { TenantsDetails }