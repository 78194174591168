import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { initialFloor, Floor } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { FloorsListLoading } from '../components/loading/FloorsListLoading'
import { createFloor, updateFloor } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


type Props = {
  isFloorLoading: boolean
  floor: Floor
}



const editFloorSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const FloorEditModalForm: FC<Props> = ({ floor, isFloorLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [floorForEdit] = useState<Floor>({
    ...floor,
    avatar: floor.avatar || initialFloor.avatar,
    role: floor.role || initialFloor.role,
    position: floor.position || initialFloor.position,
    name: floor.name || initialFloor.name,
    email: floor.email || initialFloor.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }


  return (
    <>
      <form id='kt_modal_add_floor_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_floor_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_floor_header'
          data-kt-scroll-wrappers='#kt_modal_add_floor_scroll'
          data-kt-scroll-offset='300px'
        >


          <Row className='mb-8'>
          <Col sm={12} md={6}>
          <Form.Group className="mb-8" >
                <Form.Label> Floor Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <FloorsListLoading />} */}
    </>
  )
}

export { FloorEditModalForm }
