import React from "react";
import { ComplaintsListWrapper } from "./complaints-list/ComplaintsList";



const ComplaintListing = () => {

    return (


        <>
            <h2> Complaint Management </h2>

            <div className="card p-6 mt-5">
                <ComplaintsListWrapper />
            </div>

        </>
    )




}

export { ComplaintListing }