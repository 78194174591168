import React from "react";



const Manager = () => {

return(


    <>
    </>
)




}

export {Manager}