import React from "react";
import { AddEditRentalForm } from "./AddEditRental";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";




const EditRentalForm = () => {

    const navigate = useNavigate()
    const navigateToRentalPaymentListing = () => {
        // 👇️ navigate to /contacts
        navigate('/rent-collections')
    }

    return (
        <>
            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Edit Rental Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToRentalPaymentListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary-light'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-12 mt-6">

                <AddEditRentalForm />

            </div>
        </>
    )
}

export { EditRentalForm }