import {useQuery} from 'react-query'
import {UnitEditModalForm} from './UnitEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getUnitById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const UnitEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: unit,
    error,
  } = useQuery(
    `${QUERIES.UNITS_LIST}-unit-${itemIdForUpdate}`,
    () => {
      return getUnitById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )


  if (!itemIdForUpdate) {
    return <UnitEditModalForm isUnitLoading={false} unit={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <UnitEditModalForm isUnitLoading={false} unit={dataresponse} />
  }

  return null
}

export {UnitEditModalFormWrapper}
