// @ts-nocheck
import {Column} from 'react-table'
import {PropertymanagementInfoCell} from './PropertymanagementInfoCell'
import {PropertymanagementLastLoginCell} from './PropertymanagementLastLoginCell'
import {PropertymanagementTwoStepsCell} from './PropertymanagementTwoStepsCell'
import {PropertymanagementActionsCell} from './PropertymanagementActionsCell'
import {PropertymanagementSelectionCell} from './PropertymanagementSelectionCell'
import {PropertymanagementCustomHeader} from './PropertymanagementCustomHeader'
import {PropertymanagementSelectionHeader} from './PropertymanagementSelectionHeader'
import {Propertymanagement} from '../../core/_models'

const propertymanagementsColumns: ReadonlyArray<Column<Propertymanagement>> = [
  // {
  //   Header: (props) => <PropertymanagementSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <PropertymanagementSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <PropertymanagementCustomHeader tableProps={props} title='Property Name' className='min-w-125px' />,
    accessor: 'property_name',
    Cell: ({...props}) => "RentX Avenue",
    // Cell: ({...props}) => <PropertymanagementInfoCell propertymanagement={props.data[props.row.index]} />,
  },

  // {
  //   Header: (props) => (
  //     <PropertymanagementCustomHeader tableProps={props} title='Address 1' className='min-w-125px' />
  //   ),
  //   accessor: 'address1',
  //   Cell: ({...props}) => "Address Line 1",
  // },

  // {
  //   Header: (props) => (
  //     <PropertymanagementCustomHeader tableProps={props} title='Address 2' className='min-w-125px' />
  //   ),
  //   accessor: 'address2',
  //   Cell: ({...props}) => "Address Line 2",
  // },
  {
    Header: (props) => (
      <PropertymanagementCustomHeader tableProps={props} title='Country' className='min-w-125px' />
    ),
    accessor: 'country',
    Cell: ({...props}) => "Qatar",
  },
  {
    Header: (props) => (
      <PropertymanagementCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'state',
    Cell: ({...props}) => "Doha",
  },
  {
    Header: (props) => (
      <PropertymanagementCustomHeader tableProps={props} title='City' className='min-w-125px' />
    ),
    accessor: 'city',
    Cell: ({...props}) => "Umm Salal",
  },

  {
    Header: (props) => (
      <PropertymanagementCustomHeader tableProps={props} title='Zipcode' className='min-w-125px' />
    ),
    accessor: 'zipcode',
    Cell: ({...props}) => 380652,
  },

  
  {
    Header: (props) => (
      <PropertymanagementCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <PropertymanagementActionsCell id={props.data[props.row.index].id} />,
  },
]

export {propertymanagementsColumns}
