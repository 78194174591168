/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
       <SidebarMenuItem
        to='/properties'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Properties Management'
        fontIcon='bi-layers'
      />

<SidebarMenuItem
        to='/floor-listing'
        icon='/media/icons/duotune/abstract/abs027.svg'
        title='Floor Management'
        fontIcon='bi-layers'
      />


      <SidebarMenuItem
        to='/unit-listing'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Unit Management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/tenants-details'
        icon='/media/icons/duotune/user/user01.svg'
        title='Tenants Management'
        fontIcon='bi-layers'
      />

{/* <SidebarMenuItem
        to='/floor-listing'
        icon='/media/icons/duotune/abstract/abs027.svg'
        title='Floor Management'
        fontIcon='bi-layers'
      /> */}

       <SidebarMenuItem
        to='/rent-collections'
        icon='/media/icons/duotune/finance/fin002.svg'
        title='Rent collections'
        fontIcon='bi-layers'
      />

{/* <SidebarMenuItem
        to='/rent-collections'
        icon='/media/icons/duotune/finance/fin002.svg'
        title='Rent Collection'
        fontIcon='bi-layers'
      /> */}

       <SidebarMenuItem
        to='/complaint-listing'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Complaints'
        fontIcon='bi-layers'
      />

<SidebarMenuItem
        to='/request-call-back'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Request a Call Back'
        fontIcon='bi-layers'
      />

       {/* <SidebarMenuItem
        to='/tenant-portal'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title='Tenant Portal'
        fontIcon='bi-layers'
      />

<SidebarMenuItem
        to='/manager'
        icon='/media/icons/duotune/user/user-manager.svg'
        title='Manager'
        fontIcon='bi-layers'
      />

<SidebarMenuItem
        to='/maintenance-people'
        icon='/media/icons/duotune/user/user01.svg'
        title='Maintenance People'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/contacts'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Contacts'
        fontIcon='bi-layers'
      />
<SidebarMenuItem
        to='/visitors'
        icon='/media/icons/duotune/user/users.svg'
        title='Visitors'
        fontIcon='bi-layers'
      />

<SidebarMenuItem
        to='/configurations'
        icon='/media/icons/duotune/coding/cod001.svg'
        title='Configurations'
        fontIcon='bi-layers'
      /> */}











      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
