import React from "react";
import { Modal } from "react-bootstrap";


type Props = {
    show: boolean
    handleClose: () => void
  }

const ViewMessage = ({show,handleClose}:Props) => {
    // Show for Boolean 
    // HandleClose for Void 
    return (
     
      <>
       <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message Details</Modal.Title>
            <div className="separator separator-dashed mb-2"></div>
          </Modal.Header>
          <Modal.Body>
  
          <div className="row mx-0 py-2">
                      <div className="col-md-12 col-sm-12">
                          <div className="fs-3 text-black text-start fw-normal py-2"> 
                          But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born 
                          </div>
                      </div>
                     
                  </div>
  
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
  
    )
  } 
  
  export default ViewMessage;
  