// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ComplaintsListFilter} from './ComplaintsListFilter'

const ComplaintsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddComplaintModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-unit-table-toolbar='base'>
      <ComplaintsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add unit */}
      <button type='button' className='btn btn-primary-light' onClick={openAddComplaintModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Complaint
      </button>
      {/* end::Add unit */}
    </div>
  )
}

export {ComplaintsListToolbar}
