/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteComplaint} from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import DeleteTenentsDetails from '../../../DeleteComplaintDetails'
// import DeleteComplaintsDetails from '../../../DeleteComplaintDetails'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
import DeleteComplaintsDetails from '../../../DeleteComplaintDetails'
import ViewComplaintDetails from '../../../ViewComplaintDetails'
// import DeleteComplaintsDetails from '../../../DeleteComplaintDetails'
// import ViewComplaintDetails from '../../../ViewComplaintDetails'


type Props = {
  id: ID
}

const ComplaintActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }


  // const navigate = useNavigate()
  // const navigateViewComplaintDetails = () => {
  //   // 👇️ navigate to /
  //   navigate(`/viewcomplaintdetails`)
  // }


  // const deleteItem = useMutation(() => deleteComplaint(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.UNITS_LIST}-${query}`])
  //   },
  // })


  const [ShowDeleteComplaintDetails, setShowDeleteComplaintDetails] = useState<boolean>(false)
  const [ShowViewComplaintDetails, setShowViewComplaintDetails] = useState<boolean>(false)

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* begin::Menu */}

<div className='d-flex flex-wrap flex-end'>

<div className='menu-item px-1'>
          <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
            >
          {/* <i className="fa-sharp fa-solid fa-file-lines fs-5 text-info"></i> */}
          <i className="far fa-eye fs-5 text-success"></i>
          </OverlayTrigger>
          </a>
          <ViewComplaintDetails
            show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
          /> 
        </div>

<div className='menu-item px-1'>
          <a className='menu-link p-1' onClick={openEditModal}>
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
            >
          <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i>
          </OverlayTrigger>
          </a>
        </div>

        <div className='menu-item px-1'>
          <a className='menu-link p-1' onClick={() => setShowDeleteComplaintDetails(true)} >
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
            >
          <i className="fa-solid fa-trash-can fs-4 text-danger"></i>
          </OverlayTrigger>
          </a>

           <DeleteComplaintsDetails
           show={ShowDeleteComplaintDetails} handleClose={() => setShowDeleteComplaintDetails(false)}
          /> 
        </div>

</div>



      {/* end::Menu */}
    </>
  )
}

export {ComplaintActionsCell}
