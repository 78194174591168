import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { initialRental, Rental } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { RentalsListLoading } from '../components/loading/RentalsListLoading'
import { createRental, updateRental } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


type Props = {
  isRentalLoading: boolean
  rental: Rental
}


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },

];

const unit_list = [
  { value: ' ', label: 'Select Unit Name' },
  { value: 'Select Unit One', label: 'Select Unit One' },
  { value: 'Select Unit Two', label: 'Select Unit Two' },
  { value: 'Select Unit Three', label: 'Select Unit Three' },
  { value: 'Select Unit Four', label: 'Select Unit Four' },
  { value: 'Select Unit Five', label: 'Select Unit Five' },

];

const owner_list = [
  { value: ' ', label: 'Select Owner Name' },
  { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
  { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
  { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
  { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
  { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

];


const tenant_list = [
  { value: ' ', label: 'Select Tenant Name' },
  { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
  { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
  { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
  { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
  { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

];

const editRentalSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const RentalEditModalForm: FC<Props> = ({ rental, isRentalLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [rentalForEdit] = useState<Rental>({
    ...rental,
    avatar: rental.avatar || initialRental.avatar,
    role: rental.role || initialRental.role,
    position: rental.position || initialRental.position,
    name: rental.name || initialRental.name,
    email: rental.email || initialRental.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [SelectedOwner, setSelectedOwner] = useState(null);
  const [SelectedTenant, setSelectedTenant] = useState(null);

  return (
    <>
      <form id='kt_modal_add_rental_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_rental_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_rental_header'
          data-kt-scroll-wrappers='#kt_modal_add_rental_scroll'
          data-kt-scroll-offset='300px'
        >

          <h3 className='px-0 py-3 mb-8'> Tenant Details </h3>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Select Property <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Unit Name <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedUnit}
                // onChange={setSelectedOption}
                options={unit_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Owner Name <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={SelectedOwner}
                // onChange={setSelectedOption}
                options={owner_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Tenant Name <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={SelectedTenant}
                // onChange={setSelectedOption}
                options={tenant_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Starting Date <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="Date" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Next Due Date <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="Date" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>
          <h3 className='px-0 py-3 mb-8'> Payment Details </h3>
          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Tent Amount <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Overdue Amount  </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Amount To Be Paid </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Amount Paid </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Next Due </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" disabled />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>

              <Form.Label> Mode Of Payment <span className='fs-6 text-danger'> * </span></Form.Label>
              <Col className='d-inline-block mt-3'>
                <Form.Check // prettier-ignore
                  type="radio"
                  id="dd"
                  label="DD"
                  name="group1"
                  className='d-inline-block me-10'

                />
                <Form.Check
                  type="radio"
                  label="Cash"
                  id="cash"
                  name="group1"
                  className='d-inline-block me-10'

                />
                <Form.Check
                  type="radio"
                  label="Cheque"
                  id="cheque"
                  name="group1"
                  className='d-inline-block me-10'

                />
              </Col>
            </Col>
          </Row>
          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Status</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Active"
                checked
              />
            </Col>
          </Row>
          <div className='separator mb-4'></div>
          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <RentalsListLoading />} */}
    </>
  )
}

export { RentalEditModalForm }
