// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {TenantsListFilter} from './TenantsListFilter'

const TenantsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddTenantModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateToAddTenants = () => {
      // 👇️ navigate to /contacts
      navigate('/add-tenants')
  }

  return (
    <div className='d-flex justify-content-end' data-kt-tenant-table-toolbar='base'>
      <TenantsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add tenant */}
      <button type='button' className='btn btn-primary-light' onClick={navigateToAddTenants}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Tenant
      </button>
      {/* end::Add tenant */}
    </div>
  )
}

export {TenantsListToolbar}
