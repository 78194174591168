import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {FloorsListHeader} from './components/header/FloorsListHeader'
import {FloorsTable} from './table/FloorsTable'
import {FloorEditModal} from './floor-edit-modal/FloorEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const FloorsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <FloorsListHeader />
        <FloorsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <FloorEditModal />}
    </>
  )
}

const FloorsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FloorsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FloorsListWrapper}
