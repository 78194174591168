import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Propertymanagement, PropertymanagementsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROPERTYMANAGEMENT_URL = `${API_URL}/propertymanagement`
const GET_PROPERTYMANAGEMENTS_URL = `${API_URL}/users/query`

const getPropertymanagements = (query: string): Promise<PropertymanagementsQueryResponse> => {
  return axios
    .get(`${GET_PROPERTYMANAGEMENTS_URL}?${query}`)
    .then((d: AxiosResponse<PropertymanagementsQueryResponse>) => d.data)
}

const getPropertymanagementById = (id: ID): Promise<Propertymanagement | undefined> => {
  return axios
    .get(`${PROPERTYMANAGEMENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Propertymanagement>>) => response.data)
    .then((response: Response<Propertymanagement>) => response.data)
}

const createPropertymanagement = (propertymanagement: Propertymanagement): Promise<Propertymanagement | undefined> => {
  return axios
    .put(PROPERTYMANAGEMENT_URL, propertymanagement)
    .then((response: AxiosResponse<Response<Propertymanagement>>) => response.data)
    .then((response: Response<Propertymanagement>) => response.data)
}

const updatePropertymanagement = (propertymanagement: Propertymanagement): Promise<Propertymanagement | undefined> => {
  return axios
    .post(`${PROPERTYMANAGEMENT_URL}/${propertymanagement.id}`, propertymanagement)
    .then((response: AxiosResponse<Response<Propertymanagement>>) => response.data)
    .then((response: Response<Propertymanagement>) => response.data)
}

const deletePropertymanagement = (propertymanagementId: ID): Promise<void> => {
  return axios.delete(`${PROPERTYMANAGEMENT_URL}/${propertymanagementId}`).then(() => {})
}

const deleteSelectedPropertymanagements = (propertymanagementIds: Array<ID>): Promise<void> => {
  const requests = propertymanagementIds.map((id) => axios.delete(`${PROPERTYMANAGEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getPropertymanagements, deletePropertymanagement, deleteSelectedPropertymanagements, getPropertymanagementById, createPropertymanagement, updatePropertymanagement}
