// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {PropertymanagementsListFilter} from './PropertymanagementsListFilter'

const PropertymanagementsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddPropertymanagementModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddPropery = () => {
    // 👇️ navigate to /
    navigate(`/addproperty`)
  }



  return (
    <div className='d-flex justify-content-end' data-kt-propertymanagement-table-toolbar='base'>
      <PropertymanagementsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add propertymanagement */}
      <button type='button' className='btn btn-primary-light' onClick={navigateAddPropery}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Property
      </button>
      {/* end::Add propertymanagement */}
    </div>
  )
}

export {PropertymanagementsListToolbar}
