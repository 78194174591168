import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from 'react-select';


const floor_list = [
  { value: ' ', label: 'Select' },
  { value: '1st Floor', label: '1st Floor' },
  { value: '2nd Floor', label: '2nd Floors' },
  { value: '3rd Floor', label: '3rd Floor' },
  { value: '4th Floor', label: '4th Floor' },
  { value: '5th Floor', label: '5th Floor' },

];

const unit_list = [
  { value: ' ', label: 'Select Unit' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];

// const owner_list = [
//   { value: ' ', label: 'Select Owner Name' },
//   { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
//   { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
//   { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
//   { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
//   { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

// ];


const tenant_list = [
  { value: ' ', label: 'Select Tenant Name' },
  { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
  { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
  { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
  { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
  { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

];

//   const editRentalSchema = Yup.object().shape({
//     email: Yup.string()
//       .email('Wrong email format')
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required('Email is required'),
//     name: Yup.string()
//       .min(3, 'Minimum 3 symbols')
//       .max(50, 'Maximum 50 symbols')
//       .required('Name is required'),
//   })


const AddEditRentalForm = () => {

  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [SelectedOwner, setSelectedOwner] = useState(null);
  const [SelectedTenant, setSelectedTenant] = useState(null);

  return (

    <form id='kt_modal_add_rental_form' className='form' >
      {/* begin::Scroll */}
      <div
        className='d-flex flex-column me-n7 pe-7 Krunal'
        id='kt_modal_add_rental_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_rental_header'
        data-kt-scroll-wrappers='#kt_modal_add_rental_scroll'
        data-kt-scroll-offset='300px'
      >

        <Row className='mb-8'>
          
          <Col sm={12} md={6} lg={4} xl={3 } className='mb-8'>
            <Form.Label>Floor Name <span className='fs-6 text-danger'> * </span></Form.Label>
            <Select
              defaultValue={selectedFloor}
              // onChange={setSelectedOption}
              options={floor_list}
              required
            />
          </Col>
          <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Label>Unit No <span className='fs-6 text-danger'> * </span></Form.Label>
            <Select
              defaultValue={selectedUnit}
              // onChange={setSelectedOption}
              options={unit_list}
              required
            />
          </Col>

          <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
              <Form.Label>Rent Month <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="2">January</option>
                <option value="2">February</option>
                <option value="2">March</option>
                <option value="2">April</option>
                <option value="2">May</option>
                <option value="2">June</option>
                <option value="2">July</option>
                <option value="2">August</option>
                <option value="2">September</option>
                <option value="2">Octomber</option>
                <option value="2">November</option>
                <option value="2">December</option>
              </Form.Select>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
              <Form.Label>Rent Year <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="2">2010</option>
                <option value="2">2011</option>
                <option value="2">2012</option>
                <option value="2">2013</option>
                <option value="2">2014</option>
                <option value="2">2015</option>
                <option value="2">2016</option>
                <option value="2">2017</option>
                <option value="2">2018</option>
                <option value="2">2019</option>
                <option value="2">2020</option>
                <option value="2">2021</option>
                <option value="2">2022</option>
                <option value="2">2023</option>
                <option value="2">2024</option>
                <option value="2">2025</option>
              </Form.Select>
            </Col>

                    
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Tenant Name </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" disabled />
              </Form.Group>
            </Col>
            
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Rent Amount </Form.Label>
                <Form.Control type="text" value={'QAR 11,000'} className='form-control form-control-solid' placeholder="" disabled />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Water Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Electric Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Gas Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Security Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Utility Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Other Bill </Form.Label>
                <Form.Control type="text"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Issue Date </Form.Label>
                <Form.Control type="date"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Group  >
                <Form.Label> Bill Paid Date </Form.Label>
                <Form.Control type="date"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} xl={3} className='mb-8'>
            <Form.Label>Status</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select Status</option>
                <option value="2">Paid</option>
                <option value="3">Due</option>
              </Form.Select>
            </Col>
        </Row>

    
        <div className='separator mb-4'></div>
        <div className='text-end pt-5'>

          <button
            type='submit'
            className='btn btn-primary me-3'
            data-kt-users-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>
          </button>

          <button
            type='reset'
            className='btn btn-light-danger '
            data-kt-users-modal-action='cancel'
          >
            Cancel
          </button>
        </div>

      </div>
    </form>
  )
}

export { AddEditRentalForm }