// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UnitsListFilter} from './UnitsListFilter'

const UnitsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUnitModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-unit-table-toolbar='base'>
      <UnitsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add unit */}
      <button type='button' className='btn btn-primary-light' onClick={openAddUnitModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Unit
      </button>
      {/* end::Add unit */}
    </div>
  )
}

export {UnitsListToolbar}
