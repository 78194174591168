import {useQuery} from 'react-query'
import {FloorEditModalForm} from './FloorEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getFloorById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const FloorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: floor,
    error,
  } = useQuery(
    `${QUERIES.FLOORS_LIST}-floor-${itemIdForUpdate}`,
    () => {
      return getFloorById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )


  if (!itemIdForUpdate) {
    return <FloorEditModalForm isFloorLoading={false} floor={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <FloorEditModalForm isFloorLoading={false} floor={dataresponse} />
  }

  return null
}

export {FloorEditModalFormWrapper}
