import {useListView} from '../../core/ListViewProvider'
import {RequestsListToolbar} from './RequestListToolbar'
import {RequestsListGrouping} from './RequestsListGrouping'
import {RequestsListSearchComponent} from './RequestsListSearchComponent'

const RequestsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <RequestsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <RequestsListGrouping /> : <RequestsListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {RequestsListHeader}
