import React from "react";


const Notifications = () => {

return(

    <>
        <div className='card mb-5 mb-xl-10 '>
      <div
        className='card-header border-0 cursor-pointer '
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_notifications'
        aria-expanded='true'
        aria-controls='kt_account_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Notifications</h3>
        </div>
      </div>

      <div className='card-body border-top px-9 pt-3 pb-8 notifications-list'>

        <div className='row note-unread'>
          <div className='col-md-9 px-5 py-5'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
          </div>
          <div className='col-md-3 note-time text-center py-5'>
            27 Feb 2024, <span >4:30pm</span>
          </div>
        </div>


        <div className='row note-unread'>
          <div className='col-md-9 px-5 py-5'>
           But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?
          </div>
          <div className='col-md-3 note-time text-center py-5'>
          19 Feb 2024, <span>2:00pm</span>
          </div>
        </div>

        <div className='row read'>
          <div className='col-md-9 px-5 py-5'>
          Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.
          </div>
          <div className='col-md-3 text-center py-5'>
              14 Feb 2024, <span> 12:50pm  </span>
            <p> </p>
          </div>
        </div>

        <div className='row read'>
          <div className='col-md-9 px-5 py-5'>
            But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system
          </div>
          <div className='col-md-3 text-center py-5'>
           9 Feb 2024, <span> 3:30pm </span>
          </div>
        </div>

        <div className='row note-unread'>
          <div className='col-md-9  px-5 py-5'>
           Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
          </div>
          <div className='col-md-3 text-center py-5'>
            5 Feb 2024 <span> 2:30pm </span>
          </div>
        </div>


        <div className='row read'>
          <div className='col-md-9  px-5 py-5'>
            <p className=''>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
          </div>
          <div className='col-md-3 note-time text-center py-5'>
            21 Jan 2024 <span>  1:30pm  </span>
          </div>
        </div>


        <div className='row read'>
          <div className='col-md-9 '>
            <p className=''>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto</p>
          </div>
          <div className='col-md-3 text-center py-5'>
          15 Jan 2024 <span>  4:30pm </span>
          </div>
        </div>


        <div className='row note-unread'>
          <div className='col-md-9  px-5 py-5'>
            <p className=''> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </div>
          <div className='col-md-3 text-center py-5'>
            <p>3 Jan 2024 <span> 6:00pm  </span></p>
          </div>
        </div>

        <div className='row read'>
          <div className='col-md-9  px-5 py-5'>
            <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
          </div>
          <div className='col-md-3 text-center py-5'>
            16 Nov 2023 <span> 11:am </span>
          </div>
        </div>

        <div className='row read'>
          <div className='col-md-9  px-5 py-5'>
            <p className=''>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
          </div>
          <div className='col-md-3 text-center py-5'>
            5 Nov 2023 <span> 1:20pm  </span>
          </div>
        </div>

        <div className='row note-unread'>
          <div className='col-md-9  px-5 py-5'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
          </div>
          <div className='col-md-3 text-center py-5'>
            2 Nov 2023 <span> 4:30pm  </span>
          </div>
        </div>
      </div>
</div>
    
    </>
)

}
export default Notifications