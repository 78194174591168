import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Complaint, ComplaintsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const COMPLAINT_URL = `${API_URL}/complaint`
const GET_COMPLAINTS_URL = `${API_URL}/users/query`

const getComplaints = (query: string): Promise<ComplaintsQueryResponse> => {
  return axios
    .get(`${GET_COMPLAINTS_URL}?${query}`)
    .then((d: AxiosResponse<ComplaintsQueryResponse>) => d.data)
}

const getComplaintById = (id: ID): Promise<Complaint | undefined> => {
  return axios
    .get(`${COMPLAINT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Complaint>>) => response.data)
    .then((response: Response<Complaint>) => response.data)
}

const createComplaint = (complaint: Complaint): Promise<Complaint | undefined> => {
  return axios
    .put(COMPLAINT_URL, complaint)
    .then((response: AxiosResponse<Response<Complaint>>) => response.data)
    .then((response: Response<Complaint>) => response.data)
}

const updateComplaint = (complaint: Complaint): Promise<Complaint | undefined> => {
  return axios
    .post(`${COMPLAINT_URL}/${complaint.id}`, complaint)
    .then((response: AxiosResponse<Response<Complaint>>) => response.data)
    .then((response: Response<Complaint>) => response.data)
}

const deleteComplaint = (complaintId: ID): Promise<void> => {
  return axios.delete(`${COMPLAINT_URL}/${complaintId}`).then(() => {})
}

const deleteSelectedComplaints = (complaintIds: Array<ID>): Promise<void> => {
  const requests = complaintIds.map((id) => axios.delete(`${COMPLAINT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getComplaints, deleteComplaint, deleteSelectedComplaints, getComplaintById, createComplaint, updateComplaint}
