import React from "react";
import { PropertymanagementsListWrapper } from "./propertymanagements-list/PropertymanagementsList";



const PropertyListing = () => {

    return (


        <>

                <h2> Property Managements </h2>

                <div className="card p-6 mt-5">
                    <PropertymanagementsListWrapper />
                </div>
            </>
    )




}

export { PropertyListing }