import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Request = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  mobile?: string
  role?: string
  dob?: string
  position?: string
  appartment?: String
  request_info?: string
  two_steps?: boolean
  property_info?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type RequestsQueryResponse = Response<Array<Request>>

export const initialRequest: Request = {
  avatar: 'avatars/300-6.jpg',
   position: 'Art Director',
   role: 'Administrator',
  name: '',
  email: '',
}
