import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import React from 'react'
import { initialComplaint, Complaint } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ComplaintsListLoading } from '../components/loading/ComplaintsListLoading'
import { createComplaint, updateComplaint } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import ImageUploading from 'react-images-uploading';


type Props = {
  isComplaintLoading: boolean
  complaint: Complaint
}


const unit_list = [
  { value: ' ', label: 'Select Uni No' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];

const complaint_list = [
  { value: ' ', label: 'Select' },
  { value: 'Maintenance issues', label: 'Maintenance issues' },
  { value: 'Aging appliances', label: 'Aging appliances' },
  { value: 'Utility costs', label: 'Utility costs' },
  { value: 'Rent concerns', label: 'Rent concerns' },
  { value: 'Safety', label: 'Safety' },

];


const editComplaintSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const ComplaintEditModalForm: FC<Props> = ({ complaint, isComplaintLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [complaintForEdit] = useState<Complaint>({
    ...complaint,
    avatar: complaint.avatar || initialComplaint.avatar,
    role: complaint.role || initialComplaint.role,
    position: complaint.position || initialComplaint.position,
    name: complaint.name || initialComplaint.name,
    email: complaint.email || initialComplaint.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [selectedUnit, setSelectedProperty] = useState(null);
  const [selectedComplaint, setSelectedComplaint] = useState(null);

  return (
    <>
      <form id='kt_modal_add_complaint_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_complaint_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_complaint_header'
          data-kt-scroll-wrappers='#kt_modal_add_complaint_scroll'
          data-kt-scroll-offset='300px'
        >

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Date of Reporting <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="date" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Complaint Raised By <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="aiyub@admin.com" disabled />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label> Unit Number <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedUnit}
                // onChange={setSelectedOption}
                options={unit_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
                <Form.Label> About Complaint <span className='fs-6 text-danger'> * </span></Form.Label>
                <Select
                defaultValue={selectedComplaint}
                // onChange={setSelectedOption}
                options={complaint_list}
                required
              />
            </Col>

          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Complaint Details <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control rows={3} as="textarea" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>

            </Col>

            <Col sm={12} md={6}>
            <div className="App image upload section">
              <Form.Label> Upload Images </Form.Label>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper" style={{overflow:"inherit"}}>
                    <button
                    className="py-3 px-3 fs-4 w-100"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
              
                  </div>
                )}
              </ImageUploading>

            </div>
            </Col>

          </Row>

          <Row>
            <Col>
            <div className="App image upload section">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper" style={{overflow:"inherit"}}>
                   
                    {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
                    <div className="d-flex flex-wrap">
                      {imageList.map((image, index) => (

                        <div key={index} className="image-item my-10 mx-5">
                          <img src={image['data_url']} alt="" style={{maxWidth:"100%"}} />
                          <div className="image-item__btn-wrapper text-center">
                            <button onClick={() => onImageUpdate(index)} className="me-2 px-1 update bg-light-orange text-orange"> Update </button>
                            <button onClick={() => onImageRemove(index)} className="px-1 remove"> X </button>
                          </div>
                        </div>

                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading>

            </div>
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <ComplaintsListLoading />} */}
    </>
  )
}

export { ComplaintEditModalForm }
