import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
//  import {initialQueryState} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


const floor_list = [
  { value: ' ', label: 'Select' },
  { value: '1st Floor', label: '1st Floor' },
  { value: '2nd Floor', label: '2nd Floors' },
  { value: '3rd Floor', label: '3rd Floor' },
  { value: '4th Floor', label: '4th Floor' },
  { value: '5th Floor', label: '5th Floor' },

];

const unit_list = [
  { value: ' ', label: 'Select Unit' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];

// const owner_list = [
//   { value: ' ', label: 'Select Owner Name' },
//   { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
//   { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
//   { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
//   { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
//   { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

// ];


const tenant_list = [
  { value: ' ', label: 'Select Tenant Name' },
  { value: 'Mr. Parth Dave', label: 'Mr. Parth Dave' },
  { value: 'Mr. Aiyub Panara', label: 'Mr. Aiyub Panara' },
  { value: 'Mr. Divyesh Rana', label: 'Mr. Divyesh Rana' },
  { value: 'Mr. Pravin Rathod', label: 'Mr. Pravin Rathod' },
  { value: 'Mr. Raj Jangid', label: 'Mr. Raj Jangid' },

];

const RentalsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }

  const [selectedfloor, setSelectedfloor] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [SelectedOwner, setSelectedOwner] = useState(null);
  const [SelectedTenant, setSelectedTenant] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn filter-listing me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown data-filter w-300px w-md-650px ' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-3 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        <div className='p-8'>
        <Row className='mb-5'>
          
          <Col sm={12} md={6} lg={4} xl={3 } className='mb-5'>
            <Form.Label>Floor Name </Form.Label>
            <Select
              defaultValue={selectedfloor}
              // onChange={setSelectedOption}
              options={floor_list}
              required
            />
          </Col>
          <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Label>Unit No </Form.Label>
            <Select
              defaultValue={selectedUnit}
              // onChange={setSelectedOption}
              options={unit_list}
              required
            />
          </Col>

          <Col sm={12} md={6} lg={4} className='mb-5'>
              <Form.Label>Rent Month </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="2">January</option>
                <option value="2">February</option>
                <option value="2">March</option>
                <option value="2">April</option>
                <option value="2">May</option>
                <option value="2">June</option>
                <option value="2">July</option>
                <option value="2">August</option>
                <option value="2">September</option>
                <option value="2">Octomber</option>
                <option value="2">November</option>
                <option value="2">December</option>
              </Form.Select>
            </Col>
            <Col sm={12} md={6} lg={4} className='mb-5'>
              <Form.Label>Rent Year </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="2">2010</option>
                <option value="2">2011</option>
                <option value="2">2012</option>
                <option value="2">2013</option>
                <option value="2">2014</option>
                <option value="2">2015</option>
                <option value="2">2016</option>
                <option value="2">2017</option>
                <option value="2">2018</option>
                <option value="2">2019</option>
                <option value="2">2020</option>
                <option value="2">2021</option>
                <option value="2">2022</option>
                <option value="2">2023</option>
                <option value="2">2024</option>
                <option value="2">2025</option>
              </Form.Select>
            </Col>

                    
            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Label>Tenant Name</Form.Label>
            <Select
              defaultValue={SelectedTenant}
              // onChange={setSelectedOption}
              options={tenant_list}
              required
            />
            </Col>
            
            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Group  >
                <Form.Label> Rent Amount </Form.Label>
                <Form.Control type="text" value={'QAR 11,000'} className='form-control form-control-solid' placeholder="" disabled />
              </Form.Group>
            </Col>


            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Label>Utility</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select Status</option>
                <option value="2">Water Bill</option>
                <option value="3">Electric Bill </option>
                <option value="3">Gas Bill </option>
                <option value="3">Security Bill </option>
                <option value="3">Utility Bill </option>
                <option value="3">Other Bill </option>
              </Form.Select>
            </Col>
            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Group  >
                <Form.Label> Issue Date </Form.Label>
                <Form.Control type="date"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Group  >
                <Form.Label> Bill Paid Date </Form.Label>
                <Form.Control type="date"  className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4} className='mb-5'>
            <Form.Label>Status</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select Status</option>
                <option value="2">Active</option>
                <option value="3">Inactive</option>
              </Form.Select>
            </Col>
        </Row>

          <div className='text-end pt-5'>
            <button
              type='submit'
              className='btn btn-success me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Apply</span>
            </button>

          </div>

        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { RentalsListFilter }
