import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RequestsListHeader} from './components/header/RequestsListHeader'
import {RequestsTable} from './table/RequestsTable'
import {RequestEditModal} from './request-edit-modal/RequestEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const RequestsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <RequestsListHeader />
        <RequestsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RequestEditModal />}
    </>
  )
}

const RequestsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RequestsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RequestsListWrapper}
