import React from "react";
import { UnitsListWrapper } from "./units-list/UnitsList";



const UnitListing = () => {

    return (

        <>

            <h2> Unit Management </h2>

            <div className="card p-6 mt-5">
                <UnitsListWrapper />
            </div>

        </>
    )




}

export { UnitListing }