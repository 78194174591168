import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
//  import {initialQueryState} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


const complaint_list = [
  { value: ' ', label: 'Select' },
  { value: 'Maintenance issues', label: 'Maintenance issues' },
  { value: 'Aging appliances', label: 'Aging appliances' },
  { value: 'Utility costs', label: 'Utility costs' },
  { value: 'Rent concerns', label: 'Rent concerns' },
  { value: 'Safety', label: 'Safety' },


];

const unit_list = [
  { value: ' ', label: 'Select Uni No' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];

const complaint_raised_by = [
  { value: ' ', label: 'Select ' },
  { value: 'aiyubpanara@gmail.com', label: 'aiyubpanara@gmail.com' },
  { value: 'rajjangid@gmail.com', label: 'rajjangid@gmail.com' },
  { value: 'parth@gmail.com', label: 'parth@gmail.com' },
  { value: 'pravin@gmail.com', label: 'pravin@gmail.com' },
  { value: 'divyesh@gmail.com', label: 'divyesh@gmail.com' },

];


const ComplaintsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }

  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedComplaintRaised, setSelectedComplaintRaised] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn filter-listing me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown data-filter w-300px w-md-450px ' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-3 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        <div className='p-8'>
        
          <Row className='mb-5'>
          <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Date of Reporting </Form.Label>
                <Form.Control type="date" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} className='mb-8'>
              <Form.Label>Select Unit</Form.Label>
              <Select
                defaultValue={selectedUnit}
                // onChange={setSelectedOption}
                options={unit_list}
              />
            </Col>
            <Col sm={12} md={6} className='mb-8'>
              <Form.Label>Complaint About</Form.Label>
              <Select
                defaultValue={selectedComplaint}
                // onChange={setSelectedOption}
                options={complaint_list}
              />
            </Col>

            <Col sm={12} md={6} className='mb-8'>
                <Form.Label> Complaint Raised By </Form.Label>
                <Select
                defaultValue={selectedComplaintRaised}
                // onChange={setSelectedOption}
                options={complaint_raised_by}
              />
            
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-success me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Apply</span>
            </button>

          </div>

        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ComplaintsListFilter }
