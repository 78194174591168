import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Zoom from 'react-medium-image-zoom'
import { toAbsoluteUrl } from "../../../_metronic/helpers";




const ViewRentalPaymentDetails = () => {

    const navigate = useNavigate()
    const navigateToRentalPaymentListing = () => {
        // 👇️ navigate to /contacts
        navigate('/rent-collections')
    }

    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Rental Payment Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToRentalPaymentListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-8  mt-6 col-sm-12 col-md-9 col-lg-6  m-auto h-100">


            <div className="row justify-content-center p-5">
                    <div className="" style={{ width: "200px" }}>
                        <img
                            alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                            src={toAbsoluteUrl('/media/misc/user-image.png')}
                            width="100%"
                        />
                    </div>
                </div>
                <h2 className="text-center text-primary pt-3"> Mr. Raj Jangid </h2>
                <h4 className="text-center text-info"> Invoice No: 001 </h4>
                <div className="separator separator-dashed my-4"></div>

                <div className="row ">
                    <div className="col-md-6 col-sm-12 ">
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Unit No: </span> D/501</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Rent Month: </span> February</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Rent Year: </span> 2024</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Rent: </span> QAR 8000</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Water Bill: </span> QAR 500</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Electric Bill: </span> QAR 1400</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Gas Bill: </span> QAR 350</div>
                        <div className="separator separator-dashed my-2"></div>
                      
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Security Bill: </span> QAR 200</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Utility Bill: </span> QAR 700</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Other Bill: </span> QAR 00</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Total Rent: </span> QAR 11,150</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Issue Date: </span>14/02/2022</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Paid Date: </span> 19/02/2022</div>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="fs-3 text-black text-start fw-bold"><span className="fw-normal me-3"> Status: </span> 
                        <span className="bg-light-success text-success px-3 ms-5 rounded-2 fs-6 text-center fw-semibold"> Paid </span>
                        </div>
                        <div className="separator separator-dashed my-2"></div>
                    </div>
                </div>

            </div>


        </>
    )

}

export { ViewRentalPaymentDetails }