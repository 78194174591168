import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../_metronic/helpers";



const ViewTenantDetails = () => {

    const navigate = useNavigate()
    const navigateToTenantsDetails = () => {
        // 👇️ navigate to /contacts
        navigate('/tenants-details')
    }
    return (

        <>
            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> View Tenant Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToTenantsDetails}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary-light'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-8  mt-6 col-md-6 col-sm-12 m-auto h-100">
                <div className="row justify-content-center p-5">
                    <div className="" style={{ width: "200px" }}>
                        <img
                            alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                            src={toAbsoluteUrl('/media/misc/user-image.png')}
                            width="100%"
                        />
                    </div>
                </div>
                <h2 className="text-center text-primary py-3"> Mr. Aiyub Panara <span className="bg-light-success text-success px-3 ms-5 rounded-2 fs-6 text-center fw-semibold"> Active </span></h2>
                <div className="separator separator-dashed mb-4"></div>
                <div className="row mx-0">
                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Email</span>: aiyub@gmail.com</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Contact Number</span>: +91 99989 25631</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Date of Birth</span>: 10 Nov 1983</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Tenants Info</span>: Family</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Property Info</span>: RentX Avenue</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Unit Number</span>: D/501</div>
                    </div>
                    <div className="col-md-6 col-sm-12">

                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Tenure</span>: Monthly</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Contract Start</span>: 10 Nov 2023</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Contract End</span>: 10 Oct 2024</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Rent Amount</span>: QAR 11000</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Deposite Amount</span>: QAR 25000</div>
                        <div className="separator separator-dashed mb-2"></div>
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Deposite Due</span>: QAR 00</div>
                        
                    </div>
                </div>



            </div>



        </>

    )

}


export { ViewTenantDetails }