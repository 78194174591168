import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const ViewInvoiceDetails = () => {

    const navigate = useNavigate()
    const navigateToRentCollectionListing = () => {
        // 👇️ navigate to /contacts
        navigate('/rent-collections')
    }

    function handlePrint() {
        window.print()
        //the window.print() command is what is telling the browser to print the page
    }

    return (

        <>
            <Row className="row mx-0">
                <Col md={6} sm={12}><h1> Invoice Details </h1></Col>
                <Col md={6} sm={12} className="text-end">
                    <button
                        onClick={handlePrint}
                        type='button'
                        value=''
                        className='btn btn-sm fs-4 me-5 btn btn-light-info'
                    >
                        Print
                    </button>
                    <button
                        onClick={navigateToRentCollectionListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-4 me-5 btn btn-primary-light'
                    >
                        Back
                    </button>
                </Col>
            </Row>


            <div className="row">
                <div className="card p-12 mt-6 invoice col-lg-6 col-md-12 mx-auto">
                    <div className="col-12"></div>
                    <table className="table table-responsive ">
                        <thead>
                            <tr className="pt-5" >
                                <th colSpan={2} className='fw-bold fs-4x p-0 lh-sm text-black text-center '>
                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/rentx_loader.svg')} className='h-55px' /> <span className=""> Avenue </span>
                                </th>
                            </tr>
                            <tr className="pb-3 border-bottom">
                                <th colSpan={2} className='pt-0 pb-5 border-gray-800  text-black text-center fs-3 fs-5'>
                                    Al Ahed Bldg, Al Mutaf St, Old Salata, Doha, Qatar <br />
                                    Contact:<span className="fw-bold"> +974 44437023 </span>, Email Id:<span className="fw-bold"> rentxavenue@chatmail.com </span>
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr >
                                <td className='py-10 text-white lh-sm text-start fs-3 fs-5'>
                                    <strong>Invoice # 43 </strong><br />
                                    Issue Date: <strong>Feb 14, 2022 </strong><br />
                                    Paid Date: <strong>Feb 19, 2022 </strong><br />
                                    Bill Month: <strong>February, 2022 </strong>

                                </td>

                                <td className='py-10 text-white text-end fs-3 fs-5'>
                                    <strong>Mr. Raj Jangid </strong><br />
                                    First Floor, D/501 <br />
                                    Contact: <strong> +974 44253647 </strong> <br />
                                    Email Id: <strong> rajjangid@hmail.com </strong>
                                </td>
                            </tr>
                            <tr className="" >
                                <th className='px-3 fw-bold fs-2 text-start text-white' style={{ backgroundColor:'#6c757d' }}>Description</th>
                                <th className='px-3 fw-bold fs-2 text-end text-white' style={{ backgroundColor:'#6c757d' }}> Amount </th>
                            </tr>


                            <tr className="">
                                <td className='px-3  fs-3 text-start'>Home Rent</td>
                                <td className='px-3 fs-3 text-end'> QAR 8000 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Water Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 500 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Electric Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 1600 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Gas Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 350 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Guard Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 200 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Utility Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 350 </td>
                            </tr>
                            <tr className="">
                                <td className='px-3 fs-3 text-start'>Other Bill</td>
                                <td className='px-3 fs-3 text-end'> QAR 00 </td>
                            </tr>
                            <tr className="">
                                <td colSpan={2} className='px-3 fw-bold fs-3 text-end'>Total Bill Amount: QAR 11000</td>
                            </tr>
                            <tr>
                                {/* <td className='px-3 fs-3 text-end'> </td> */}
                                <td  className='px-3 fs-3 text-start border-0'>
                                    <p>Mode of Payment: <span className="fw-bold">By Cash</span></p> 
                                     <p className="badge badge-light-success fs-4x fw-bolder text=center"> Paid </p>
                                      </td>

                                <td  className='px-3 fs-3 text-start sign'> 
                                </td>
                            </tr>
                            <tr className="">
                            <td className='px-3 border-0 fs-3 text-end'>&nbsp;</td>
                                <td className='px-3 border-0 fs-3 text-center'>Signature</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>




        </>
    )

}

export default ViewInvoiceDetails