/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteRequest} from '../../core/_requests'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import DeleteTenentsDetails from '../../../DeleteRequestDetails'
// import DeleteRequestsDetails from '../../../DeleteRequestDetails'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
import ViewMessage from '../../../ViewMessage'
import DeleteRequest from '../../../DeleteRequest'
// import DeleteRequestsDetails from '../../../DeleteRequestDetails'
// import ViewRequestDetails from '../../../ViewRequestDetails'


type Props = {
  id: ID
}

const RequestActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // const openEditModal = () => {
  //   setItemIdForUpdate(id)
  // }


  // const navigate = useNavigate()
  // const navigateViewRequestDetails = () => {
  //   // 👇️ navigate to /
  //   navigate(`/viewrequestdetails`)
  // }


  // const deleteItem = useMutation(() => deleteRequest(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.UNITS_LIST}-${query}`])
  //   },
  // })


  const [ShowDeleteRequest, setShowDeleteRequest] = useState<boolean>(false)
  const [ShowViewMessage, setShowViewMessage] = useState<boolean>(false)

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* begin::Menu */}

<div className='d-flex flex-wrap flex-end'>

<div className='menu-item px-2'>
          <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
            >
          <i className="far fa-envelope fs-2 text-warning"></i>
          </OverlayTrigger>
          </a>
          <ViewMessage
            show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
          /> 
        </div>

<div className='menu-item px-2'>
          <a className='menu-link p-1'>
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Call</Tooltip>}
            >
          {/* <i className="far fa-pen-to-square fs-4 text-warning"></i> */}
          <i className="fas fa-phone-square-alt fs-3 text-success"></i>
          </OverlayTrigger>
          </a>
        </div>

        <div className='menu-item px-2'>
          <a className='menu-link p-1' onClick={() => setShowDeleteRequest(true)} >
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
            >
          <i className="fa-solid fa-trash-can fs-4 text-danger"></i>
          </OverlayTrigger>
          </a>

           <DeleteRequest
           show={ShowDeleteRequest} handleClose={() => setShowDeleteRequest(false)}
          /> 
        </div>

</div>



      {/* end::Menu */}
    </>
  )
}

export {RequestActionsCell}
