// @ts-nocheck
import {Column} from 'react-table'
import {RequestInfoCell} from './RequestInfoCell'
import {RequestLastLoginCell} from './RequestLastLoginCell'
import {RequestTwoStepsCell} from './RequestTwoStepsCell'
import {RequestActionsCell} from './RequestActionsCell'
import {RequestSelectionCell} from './RequestSelectionCell'
import {RequestCustomHeader} from './RequestCustomHeader'
import {RequestSelectionHeader} from './RequestSelectionHeader'
import {Request} from '../../core/_models'

const requestsColumns: ReadonlyArray<Column<Request>> = [
  // {
  //   Header: (props) => <RequestSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <RequestSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <RequestCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    // Cell: ({...props}) => <RequestInfoCell request={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <RequestCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },

  {
    Header: (props) => (
      <RequestCustomHeader tableProps={props} title='Mobile No' className='min-w-125px' />
    ),
    accessor: 'mobile',
    Cell: ({...props}) => 9887766550,
  },

  {
    Header: (props) => (
      <RequestCustomHeader tableProps={props} title='Request Date' className='min-w-125px' />
    ),
    accessor: 'joined_day',
    // Cell: ({...props}) => <RequestLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },

  {
    Header: (props) => (
      <RequestCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <RequestTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },

  {
    Header: (props) => (
      <RequestCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RequestActionsCell id={props.data[props.row.index].id} />,
  },
]

export {requestsColumns}
