/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget1,
} from '../../../_metronic/partials/widgets'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
import ViewComplaintDetails from '../../modules/complaints/ViewComplaintDetails'
import ViewMessage from '../../modules/request-call-back/ViewMessage'


const DashboardPage: FC = () => {

  const [ShowViewComplaintDetails, setShowViewComplaintDetails] = useState<boolean>(false)
  const [ShowViewMessage, setShowViewMessage] = useState<boolean>(false)

 
  return (
    <>
      {/* begin::Row */}
      {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
    
    </div> */}
      <div className="row  mb-5 mt-5 dashboard">
        <div className=' col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5 '>
          <div className='card flex-row bg-primary border p-5 border-1'>
            <div className='col-md-12 col-lg-6 pt-3'>
              <h4 className='text-white fs-1x'> Total Floor </h4>
              <h3 className='text-light-primary fs-3x '> 12 </h3>
            </div>
            <div className='col-md-12 col-lg-6 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/floor-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5 '>
          <div className='card flex-row bg-info border p-5 border-1'>
            <div className='col-md-12 col-lg-6 pt-3'>
              <h4 className='text-white fs-1x'> Total Unit </h4>
              <h3 className='text-light-info fs-3x '> 15 </h3>
            </div>
            <div className='col-md-12 col-lg-6 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/unit-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5'>
          <div className='card flex-row bg-blue border p-5 border-1'>
            <div className='col-md-12 col-lg-6 pt-3'>
              <h4 className='text-white fs-1x'> Total Owner </h4>
              <h3 className='text-light-blue fs-3x '> 8 </h3>
            </div>
            <div className='col-md-12 col-lg-6 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/owner-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>

        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5'>
          <div className='card flex-row bg-warning border p-5 border-1'>
            <div className='col-md-12 col-lg-6 pt-3'>
              <h4 className='text-white fs-1x'> Total Tenants </h4>
              <h3 className='text-light-warning fs-3x '> 11 </h3>
            </div>
            <div className='col-md-12 col-lg-6 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/tenant-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>
        </div>
     
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5'>

          <div className='card flex-row  bg-success border p-5 border-1'>
            <div className='col-md-12 col-lg-7 pt-3'>
              <h4 className='text-white fs-1x'> Total Rent </h4>
              <h3 className='text-light-success fs-2x'> QAR 1,65,326 </h3>
            </div>
            <div className='col-md-12 col-lg-5 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/rent-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>

        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5'>

          <div className='card flex-row bg-secondary border p-5 border-1'>
            <div className='col-md-12 col-lg-7 pt-3'>
              <h4 className='text-white fs-1x'> Total Maintenance </h4>
              <h3 className='text-light-secondary fs-2x'> QAR 25,304 </h3>
            </div>
            <div className='col-md-12 col-lg-5 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/maintenance-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-5'>
          <div className='card flex-row bg-danger border p-5 border-1'>
            <div className='col-md-12 col-lg-7 pt-3'>
              <h4 className='text-white fs-1x'> Total Complaint </h4>
              <h3 className='text-light-danger fs-3x '> 6 </h3>
            </div>
            <div className='col-md-12 col-lg-5 text-center'>
              <div className='icon-bg mx-auto'>
                <img src={toAbsoluteUrl('/media/misc/complaint-icon.png')} alt='' className='h-75px' />
              </div>
            </div>
          </div>

        </div>

      </div>

<div className='row '>
<div className='col-12 '>
          <div className='card border'>
            {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />*/}

            <ChartsWidget1 className='mb-5' />
          </div>
        </div>
</div>

      <div className='row mt-5 dashboard-data'>
        <div className='col-sm-12 col-md-6 mb-6'>
          <div className='card border p-8'>
            <div className='card_body'>
              <h3 className=' mb-5 text-black'> Last 5 Complaint </h3>
              <div className='separator mb-5'></div>
              <table className="table table-responsive dash-table">
                <thead>
                  <tr>
                    <th scope="col" className='p-3 fw-bold fs-4'>Title</th>
                    <th scope="col" className='fw-bold fs-4'> Issue Date </th>
                    <th scope="col" className='fw-bold fs-4'>Complain Raised BY</th>
                    <th scope="col" className='fw-bold fs-4'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Maintanance</td>
                    <td className='p-3'>28/03/2024</td>
                    <td>Mr. Pravin Rathod</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
                        >
                          <i className="far fa-eye fs-5 text-success"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewComplaintDetails
                        show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>Aging Appliance</td>
                    <td className='p-3'>19/03/2024</td>
                    <td>Mr. Mayur Hole</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
                        >
                          <i className="far fa-eye fs-5 text-success"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewComplaintDetails
                        show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Safety</td>
                    <th className='p-3'>14/03/2024</th>
                    <td>Mr. Raj Jangid</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
                        >
                          <i className="far fa-eye fs-5 text-success"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewComplaintDetails
                        show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
                      />
                    </td>
                  </tr>


                  <tr>
                    <td>Utility</td>
                    <td className='p-3'>07/03/2024</td>
                    <td>Mr. Divyesh Rana</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
                        >
                          <i className="far fa-eye fs-5 text-success"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewComplaintDetails
                        show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Rent Concerns</td>
                    <td className='p-3'>28/02/2024</td>
                    <td>Mr. Irfan Pathan</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewComplaintDetails(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
                        >
                          <i className="far fa-eye fs-5 text-success"></i>
                        </OverlayTrigger>
                      </a>
                      {/* <ViewComplaintDetails
                        show={ShowViewComplaintDetails} handleClose={() => setShowViewComplaintDetails(false)}
                      /> */}
                    </td>

                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 mb-6'>
          <div className='card border p-8'>
            <div className='card_body'>
              <h3 className=' mb-5 text-black'> Last 5 Request Call Back </h3>
              <div className='separator mb-5'></div>
              <table className="table table-responsive dash-table">
                <thead>
                  <tr>
                    <th scope="col" className='p-3 fw-bold fs-4'>Name</th>
                    <th scope="col" className='fw-bold fs-4'> Mobile No </th>
                    <th scope="col" className='fw-bold fs-4'>Request Date</th>
                    <th scope="col" className='fw-bold fs-4'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Emma Smith</td>
                    <td>9887766550</td>
                    <td className='p-3'>28/03/2024</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
                        >
                          <i className="far fa-envelope fs-2 text-warning"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewMessage
                        show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>Melody Macy</td>
                    <td>9887766584</td>
                    <td className='p-3'>19/03/2024</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
                        >
                          <i className="far fa-envelope fs-2 text-warning"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewMessage
                        show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
                      />
                    </td>

                  </tr>

                  <tr>
                    <td>Brian Cox</td>
                    <td> 9856452315 </td>
                    <th className='p-3'>14/03/2024</th>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
                        >
                          <i className="far fa-envelope fs-2 text-warning"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewMessage
                        show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
                      />
                    </td>
                  </tr>


                  <tr>
                    <td>Utility</td>
                    <td> 9987523654</td>
                    <td className='p-3'>07/03/2024</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
                        >
                          <i className="far fa-envelope fs-2 text-warning"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewMessage
                        show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Rent Concerns</td>
                    <td> 9854263254</td>
                    <td className='p-3'>28/02/2024</td>
                    <td>
                      <a className='menu-link p-1' onClick={() => setShowViewMessage(true)}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip className='py-2 px-2 fs-5 '>Message</Tooltip>}
                        >
                          <i className="far fa-envelope fs-2 text-warning"></i>
                        </OverlayTrigger>
                      </a>
                      <ViewMessage
                        show={ShowViewMessage} handleClose={() => setShowViewMessage(false)}
                      />
                    </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>


    </>

  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <h2> Dashboard </h2>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
