import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Floor, FloorsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const FLOOR_URL = `${API_URL}/floor`
const GET_FLOORS_URL = `${API_URL}/users/query`

const getFloors = (query: string): Promise<FloorsQueryResponse> => {
  return axios
    .get(`${GET_FLOORS_URL}?${query}`)
    .then((d: AxiosResponse<FloorsQueryResponse>) => d.data)
}

const getFloorById = (id: ID): Promise<Floor | undefined> => {
  return axios
    .get(`${FLOOR_URL}/${id}`)
    .then((response: AxiosResponse<Response<Floor>>) => response.data)
    .then((response: Response<Floor>) => response.data)
}

const createFloor = (floor: Floor): Promise<Floor | undefined> => {
  return axios
    .put(FLOOR_URL, floor)
    .then((response: AxiosResponse<Response<Floor>>) => response.data)
    .then((response: Response<Floor>) => response.data)
}

const updateFloor = (floor: Floor): Promise<Floor | undefined> => {
  return axios
    .post(`${FLOOR_URL}/${floor.id}`, floor)
    .then((response: AxiosResponse<Response<Floor>>) => response.data)
    .then((response: Response<Floor>) => response.data)
}

const deleteFloor = (floorId: ID): Promise<void> => {
  return axios.delete(`${FLOOR_URL}/${floorId}`).then(() => {})
}

const deleteSelectedFloors = (floorIds: Array<ID>): Promise<void> => {
  const requests = floorIds.map((id) => axios.delete(`${FLOOR_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getFloors, deleteFloor, deleteSelectedFloors, getFloorById, createFloor, updateFloor}
