import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageUploading from 'react-images-uploading';
import Select from 'react-select';
import { AddEditProperty } from "./AddEditProperty";




const EditProperty = () => {

  const navigate = useNavigate()
  const navigateToPropertyListing = () => {
    // 👇️ navigate to /contacts
    navigate('/properties')
  }

  return (




    <>
      <Row className="row mx-0">
        <Col md={8} sm={12}><h1> Edit Property Details </h1></Col>
        <Col md={4} sm={12} className="text-end">
          <button
            onClick={navigateToPropertyListing}
            type='button'
            value=''
            className='btn btn-sm fs-5 me-5 btn btn-primary-light'
          >
            Back
          </button>
        </Col>
      </Row>

      <div className="card p-12 mt-6">

  <AddEditProperty />

      </div>

    </>


  )

}

export { EditProperty }