import React from "react";
import { FloorsListWrapper } from "./floors-list/FloorsList";



const FloorListing = () => {

return(

    <>
    
    <h2> Floor Management </h2>

<div className="card p-6 mt-5">
    <FloorsListWrapper />
</div>
    
    </>
)

}

export {FloorListing}