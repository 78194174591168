import React from "react";
import react, { useState } from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { useListView } from "./complaints-list/core/ListViewProvider";
import { KTSVG } from "../../../_metronic/helpers";
// import { StepperComponent } from "../../../../_metronic/assets/ts/components";

type Props = {
  show: boolean
  handleClose: () => void
}


const ViewComplaintDetails = ({show,handleClose}:Props) => {
  // Show for Boolean 
  // HandleClose for Void 
  return (
   
    <>
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Complaint Details</Modal.Title>
          <div className="separator separator-dashed mb-2"></div>
        </Modal.Header>
        <Modal.Body className="pt-2">

        <div className="row mx-0 py-2">
                    <div className="col-md-8 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal "> Reporting Date: </span> 18th Jan 2024 </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="fs-3 text-black text-end fw-bold py-2"> <span className="badge badge-light-warning fs-1"> Pending </span>  </div>
                    </div>
                </div>

                <div className="row mx-0 py-2">
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-info text-start fw-bold py-2"> <span className="fw-normal ">Unit No:</span> D/501 </div>
                    </div>
                    <div className="separator separator-dashed mb-2"></div>
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal "> Complaint:</span> Maintenance issues </div>
                    </div>
                    <div className="separator separator-dashed mb-2"></div>
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-normal py-2"> Complaint Details: </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2">  Maintenance issues were by far the most common tenant issues listed. From plumbing and water backup complaints to water heater or furnace issues to regular wear and tear. </div>
                    </div>
                    <div className="separator separator-dashed mt-8 "></div>
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-4 text-black text-end fw-bold py-2"> <span className="fw-normal "> Complaint Raised By :</span> aiyubpanara@gmail.com </div>
                    </div>
                </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>

  )
}

export default ViewComplaintDetails