// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {FloorsListFilter} from './FloorsListFilter'

const FloorsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddFloorModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-floor-table-toolbar='base'>
      <FloorsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add floor */}
      <button type='button' className='btn btn-primary-light' onClick={openAddFloorModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Floor
      </button>
      {/* end::Add floor */}
    </div>
  )
}

export {FloorsListToolbar}
