import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
//  import {initialQueryState} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import { auto } from '@popperjs/core'


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },


];

const unit_list = [
  { value: ' ', label: 'Select Unit No' },
  { value: 'D/501', label: 'D/501' },
  { value: 'D/502', label: 'D/502' },
  { value: 'D/503', label: 'D/503' },
  { value: 'D/504', label: 'D/504' },
  { value: 'D/505', label: 'D/505' },

];

const name_list = [
  { value: ' ', label: 'Select Name' },
  { value: 'Emma Smith', label: 'Emma Smith' },
  { value: 'Melody Macy', label: 'Melody Macy' },
  { value: 'Sean Bean', label: 'Sean Bean' },
  { value: 'Brian Cox', label: 'Brian Cox' },
  { value: 'Francis Mitcham', label: 'Francis Mitcham' },

];

const email_list = [
  { value: ' ', label: 'Select Email ID' },
  { value: 'smith@kpmg.com', label: 'smith@kpmg.com' },
  { value: 'melody@altbox.com', label: 'melody@altbox.com' },
  { value: 'sean@dellito.com', label: 'sean@dellito.com' },
  { value: 'brian@exchange.com', label: 'brian@exchange.com' },
  { value: 'f.mit@kpmg.com', label: 'f.mit@kpmg.com' },

];

const mobile_list = [
  { value: ' ', label: 'Select Mobile No' },
  { value: '9825042358', label: '9825042358' },
  { value: '9756824156', label: '9756824156' },
  { value: '9825478654', label: '9825478654' },
  { value: '9958742564', label: '9958742564' },
  { value: '9968453214', label: '9968453214' },

];


const TenantsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedEmail, setselectedEmail] = useState(null);
  const [selectedMobile, setselectedMobile] = useState(null);




  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn filter-listing me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown data-filter w-350px w-md-800px ' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-3 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        <div className='p-8' style={{maxHeight:'600px', overflowY:auto}}>
          <Row className='mb-5'>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Select Name</Form.Label>
              <Select
                defaultValue={selectedName}
                // onChange={setSelectedOption}
                options={name_list}
              />
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Label>Select Email </Form.Label>
              <Select
                defaultValue={selectedEmail}
                // onChange={setSelectedOption}
                options={email_list}
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Select Mobile No </Form.Label>
              <Select
                defaultValue={selectedMobile}
                // onChange={setSelectedOption}
                options={mobile_list}
              />
            </Col>
          </Row>

          <Row className='mb-5'>
          <Col sm={12} md={6} lg={4}>
              <Form.Label>Tenant Info</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="2">Individual</option>
                <option value="3">Family</option>

              </Form.Select>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Select Property</Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label>Select Unit</Form.Label>
              <Select
                defaultValue={selectedUnit}
                // onChange={setSelectedOption}
                options={unit_list}
              />
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col sm={12} md={6} lg={4}>
              <Row>
                <Form.Label> Tenure </Form.Label>
              </Row>
              <Col className='d-inline-block mt-3'>
                <Form.Check // prettier-ignore
                  type="radio"
                  id="dd"
                  label="Monthly"
                  name="tenure"
                  className='d-inline-block me-10'

                />
                <Form.Check
                  type="radio"
                  label="Yearly"
                  id="cash"
                  name="tenure"
                  className='d-inline-block me-10'

                />

              </Col>


            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Group >
                <Form.Label> Rent Amount </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>

          </Row>

          <Row className='mb-5'>
            <Col sm={12} md={12}>
              <Form.Label> Utility  </Form.Label>
              <div className="d-flex flex-wrap">
                <div className="form-check col-sm-6 col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label  me-6 mb-3" >
                    Water Bill
                  </label>
                </div>
                <div className="form-check col-sm-6  col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                  <label className="form-check-label  me-6 mb-3" >
                    Electric Bill
                  </label>
                </div>
                <div className="form-check col-sm-6  col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                  <label className="form-check-label  me-6 mb-3" >
                    Gas Bill
                  </label>
                </div>
                <div className="form-check col-sm-6  col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                  <label className="form-check-label  me-6 mb-3" >
                    Security Bill
                  </label>
                </div>
                <div className="form-check col-sm-6  col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                  <label className="form-check-label  me-6 mb-3" >
                    Utility Bill
                  </label>
                </div>
                <div className="form-check col-sm-6  col-md-4 col-lg-3">
                  <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                  <label className="form-check-label  me-6 mb-3" >
                    Other Bill
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-5'>

          <Col sm={12} md={6} lg={4}>
              <Form.Group >
                <Form.Label> Deposite Amount  </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group  >
                <Form.Label> Deposite Due </Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Form.Label className="d-block"> Mode Of Payment </Form.Label>

              <Col className='d-inline-block mt-3'>
                <Form.Check // prettier-ignore
                  type="radio"
                  id="dd"
                  label="DD"
                  name="group1"
                  className='d-inline-block me-5 mb-3'

                />
                <Form.Check
                  type="radio"
                  label="Cash"
                  id="cash"
                  name="group1"
                  className='d-inline-block me-5 mb-3'

                />
                <Form.Check
                  type="radio"
                  label="Cheque"
                  id="cheque"
                  name="group1"
                  className='d-inline-block me-5 mb-3'

                />
              </Col>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" >
                <Form.Label>Issue Date <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="date" className='form-control form-control-solid' placeholder=" " required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Status</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select Status</option>
                <option value="2">Active</option>
                <option value="3">Inactive</option>

              </Form.Select>
              {/* <Form.Label>Status</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Active"
                className='fs-4'
              /> */}
            </Col>
          </Row>


          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-success me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Apply</span>
            </button>

          </div>

        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { TenantsListFilter }
