import {useListView} from '../../core/ListViewProvider'
import {FloorsListToolbar} from './FloorListToolbar'
import {FloorsListGrouping} from './FloorsListGrouping'
import {FloorsListSearchComponent} from './FloorsListSearchComponent'

const FloorsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <FloorsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <FloorsListGrouping /> : <FloorsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {FloorsListHeader}
