import {useListView} from '../../core/ListViewProvider'
import {PropertymanagementsListToolbar} from './PropertymanagementListToolbar'
import {PropertymanagementsListGrouping} from './PropertymanagementsListGrouping'
import {PropertymanagementsListSearchComponent} from './PropertymanagementsListSearchComponent'

const PropertymanagementsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <PropertymanagementsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <PropertymanagementsListGrouping /> : <PropertymanagementsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PropertymanagementsListHeader}
