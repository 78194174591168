// @ts-nocheck
import {Column} from 'react-table'
import {UnitInfoCell} from './UnitInfoCell'
import {UnitLastLoginCell} from './UnitLastLoginCell'
import {UnitTwoStepsCell} from './UnitTwoStepsCell'
import {UnitActionsCell} from './UnitActionsCell'
import {UnitSelectionCell} from './UnitSelectionCell'
import {UnitCustomHeader} from './UnitCustomHeader'
import {UnitSelectionHeader} from './UnitSelectionHeader'
import {Unit} from '../../core/_models'

const unitsColumns: ReadonlyArray<Column<Unit>> = [
  // {
  //   Header: (props) => <UnitSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UnitSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UnitCustomHeader tableProps={props} title='Property Name' className='min-w-125px' />,
    accessor: 'Property_name',
    Cell: ({...props}) => "RentX Avenue"
    // Cell: ({...props}) => <UnitInfoCell unit={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UnitCustomHeader tableProps={props} title='Floor Name' className='min-w-125px' />,
    accessor: 'floor_name',
    Cell: ({...props}) => "Fifth Floor"
  },
  {
    Header: (props) => (
      <UnitCustomHeader tableProps={props} title='Unit No' className='min-w-125px' />
    ),
    accessor: 'unit_no',
    Cell: ({...props}) => "D/501",
  },

  

  {
    Header: (props) => (
      <UnitCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UnitActionsCell id={props.data[props.row.index].id} />,
  },
]

export {unitsColumns}
