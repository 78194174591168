import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Tenant, TenantsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TENANT_URL = `${API_URL}/tenant`
const GET_TENANTS_URL = `${API_URL}/users/query`

const getTenants = (query: string): Promise<TenantsQueryResponse> => {
  return axios
    .get(`${GET_TENANTS_URL}?${query}`)
    .then((d: AxiosResponse<TenantsQueryResponse>) => d.data)
}

const getTenantById = (id: ID): Promise<Tenant | undefined> => {
  return axios
    .get(`${TENANT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Tenant>>) => response.data)
    .then((response: Response<Tenant>) => response.data)
}

const createTenant = (tenant: Tenant): Promise<Tenant | undefined> => {
  return axios
    .put(TENANT_URL, tenant)
    .then((response: AxiosResponse<Response<Tenant>>) => response.data)
    .then((response: Response<Tenant>) => response.data)
}

const updateTenant = (tenant: Tenant): Promise<Tenant | undefined> => {
  return axios
    .post(`${TENANT_URL}/${tenant.id}`, tenant)
    .then((response: AxiosResponse<Response<Tenant>>) => response.data)
    .then((response: Response<Tenant>) => response.data)
}

const deleteTenant = (tenantId: ID): Promise<void> => {
  return axios.delete(`${TENANT_URL}/${tenantId}`).then(() => {})
}

const deleteSelectedTenants = (tenantIds: Array<ID>): Promise<void> => {
  const requests = tenantIds.map((id) => axios.delete(`${TENANT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTenants, deleteTenant, deleteSelectedTenants, getTenantById, createTenant, updateTenant}
