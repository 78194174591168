import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageUploading from 'react-images-uploading';
import Select from 'react-select';
import { toAbsoluteUrl } from "../../../_metronic/helpers";


const city_list = [
  { value: ' ', label: 'Select City' },
  { value: 'Abu Dhalouf', label: 'Abu Dhalouf' },
  { value: 'Ain Sinan', label: 'Ain Sinan' },
  { value: 'Al Aziziya', label: 'Al Aziziya' },
  { value: 'Al Bidda', label: 'Al Bidda' },
  { value: 'Fereej Kulaib', label: 'Fereej Kulaib' },

];

const zipcode_list = [
  { value: ' ', label: 'Select Zipcode' },
  { value: '380245', label: '380245' },
  { value: '360452', label: '360452' },
  { value: '370052', label: '370052' },
  { value: '367412', label: '367412' },
  { value: '385214', label: '385214' },

];

const state_list = [
  { value: ' ', label: 'Select State' },
  { value: 'Al Shamal', label: 'Al Shamal' },
  { value: ' Al Rayyan', label: ' Al Rayyan' },
  { value: 'Doha', label: 'Doha' },
  { value: 'Umm Salal', label: 'Umm Salal' },
  { value: 'Al Wakrah', label: 'Al Wakrah.' },

];


const AddEditProperty = () => {

  const navigate = useNavigate()
  const navigateToPropertyListing = () => {
    // 👇️ navigate to /contacts
    navigate('/properties')
  }

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const [selectedzipcode, setSelectedzipcode] = useState(null);
  const [selectedcity, setSelectedcity] = useState(null);
  const [selectedstate, setSelectedstate] = useState(null);


  return (

    <>


      <form id='kt_modal_add_tenant_form' className='form' >
        {/* begin::Scroll */}

        <Row className='mb-8'>
          <Col sm={12} md={6} lg={4} >
            <Form.Group className="mb-8" >
              <Form.Label> Property Name <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control className='form-control form-control-solid' placeholder="" required />
            </Form.Group>

          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label> Description <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control type="text" as="textarea" rows={3} className='form-control form-control-solid' placeholder=" " required />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <div className="App image upload section">
              <Form.Label> Upload Images </Form.Label>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      className="py-3 px-3 fs-4"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                    &nbsp;
                    {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}

                  </div>
                )}
              </ImageUploading>

            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpdate,
                onImageRemove,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">

                  &nbsp;
                  {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
                  <div className="d-flex flex-wrap">
                    {imageList.map((image, index) => (

                      <div key={index} className="image-item my-10 mx-5">
                        <img src={image['data_url']} alt="" style={{ maxWidth: "100%" }} />
                        <div className="image-item__btn-wrapper text-center">
                          {/* <button onClick={() => onImageUpdate(index)} className="me-2 px-1 update bg-light-orange text-orange"> Update </button> */}
                          <button onClick={() => onImageRemove(index)} className="px-1 remove"> X </button>
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>

          </Col>
        </Row>

        <h3 className='  py-3 mb-8' > Select Property Location </h3>

        <Row className='mb-8 row align-items-center'>
          <Col sm={12} md={6} lg={4} className="row">

            <Form.Group className="mb-5" >
              <Form.Label> Location Name <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control className='form-control form-control-solid' placeholder="" required />
            </Form.Group>


            <Form.Group className="mb-5" >
              <Form.Label> latitude <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control className='form-control form-control-solid' placeholder="" required />
            </Form.Group>


            <Form.Group className="mb-3" >
              <Form.Label> longitude <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control className='form-control form-control-solid' placeholder="" required />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={8}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d923089.4723735302!2d50.92460003653077!3d25.343879129823268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x1cfa88cf812b4032!2sQatar!5e0!3m2!1sen!2sin!4v1709726893987!5m2!1sen!2sin" width="100%" height="300" style={{ border: "0" }} allowFullScreen loading="lazy" referrerPolicy=""></iframe>
          </Col>
        </Row>



        <h3 className='  py-3 mb-8' > Address Information </h3>

        <Row className='mb-8'>
          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label> Address 1 <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
            </Form.Group>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label> Address 2 <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
            </Form.Group>
          </Col>
          <Col sm={6} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label> Select Country  <span className='fs-6 text-danger'> * </span></Form.Label>
              <Form.Control type="text" value={'Qatar'} className='form-control form-control-solid' placeholder="" disabled />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-8'>


          <Col sm={6} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label>Select City <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedcity}
                // onChange={setSelectedOption}
                options={city_list}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={6} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label>Select State <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedstate}
                // onChange={setSelectedOption}
                options={state_list}
                required
              />
            </Form.Group>
          </Col>
          <Col sm={6} md={6} lg={4}>
            <Form.Group className="mb-8" >
              <Form.Label>Zipcode <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedzipcode}
                // onChange={setSelectedOption}
                options={zipcode_list}
                required
              />
            </Form.Group>
          </Col>

        </Row>




        <div className='text-end pt-5'>

          <button
            type='submit'
            className='btn btn-primary me-3'
            data-kt-users-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>
          </button>

          <button
            type='reset'
            className='btn btn-light-danger '
            data-kt-users-modal-action='cancel'
          >
            Cancel
          </button>
        </div>


      </form>



    </>


  )

}

export { AddEditProperty }