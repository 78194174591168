import React from "react";
import react, { useState } from 'react'
import { Button, Col, Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { useListView } from "./units-list/core/ListViewProvider";
import { KTSVG } from "../../../_metronic/helpers";
// import { StepperComponent } from "../../../../_metronic/assets/ts/components";

type Props = {
  show: boolean
  handleClose: () => void
}


const ViewUnitDetails = ({show,handleClose}:Props) => {
  // Show for Boolean 
  // HandleClose for Void 
  return (
   
    <>
     <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >View Details</Modal.Title>
          <div className="separator separator-dashed mb-2"></div>
        </Modal.Header>
        <Modal.Body className="px-3">

        <div className="row mx-0 py-2">
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal ">Property Name:</span> RentX Appartment</div>
                    </div>
                    <div className="separator separator-dashed mb-2"></div>
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal ">Floor Name:</span> Fifth Floor</div>
                    </div>
                    <div className="separator separator-dashed mb-2"></div>
                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Unit No:</span> D/501 </div>
                    </div>

                </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>

  )
}

export default ViewUnitDetails