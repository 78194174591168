import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
//  import {initialQueryState} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },

];

const address1_list = [
  { value: ' ', label: 'Select Address 1' },
  { value: 'Select Address 1', label: 'Select Address 1' },
  { value: 'Select Address 1', label: 'Select Address 1' },
  { value: 'Select Address 1', label: 'Select Address 1' },
  { value: 'Select Address 1', label: 'Select Address 1' },
  { value: 'Select Address 1', label: 'Select Address 1' },

];

const address2_list = [
  { value: ' ', label: 'Select Address 2' },
  { value: 'Select Address 2', label: 'Select Address 2' },
  { value: 'Select Address 2', label: 'Select Address 2' },
  { value: 'Select Address 2', label: 'Select Address 2' },
  { value: 'Select Address 2', label: 'Select Address 2' },
  { value: 'Select Address 2', label: 'Select Address 2' },

];

const state_list = [
  { value: ' ', label: 'Select State' },
  { value: 'Al Shamal', label: 'Al Shamal' },
  { value: ' Al Rayyan', label: ' Al Rayyan' },
  { value: 'Doha', label: 'Doha' },
  { value: 'Umm Salal', label: 'Umm Salal' },
  { value: 'Al Wakrah', label: 'Al Wakrah.' },

];

const city_list = [
  { value: ' ', label: 'Select City' },
  { value: 'Abu Dhalouf', label: 'Abu Dhalouf' },
  { value: 'Ain Sinan', label: 'Ain Sinan' },
  { value: 'Al Aziziya', label: 'Al Aziziya' },
  { value: 'Al Bidda', label: 'Al Bidda' },
  { value: 'Fereej Kulaib', label: 'Fereej Kulaib' },


];

const zipcode_list = [
  { value: ' ', label: 'Select Zipcode' },
  { value: '385034', label: '385034' },
  { value: '365421', label: '365421' },
  { value: '386457', label: '386457' },
  { value: '369785', label: '369785' },
  { value: '359874', label: '359874' },

];

const PropertymanagementsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedAddress1, setselectedAddress1] = useState(null);
  const [selectedAddress2, setselectedAddress2] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [selectedCity, setselectedCity] = useState(null);
  const [selectedZipcode, setselectedZipcode] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn bg-orange-light me-3 filter-listing'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown data-filter w-300px w-md-450px ' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-3 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        <div className='p-8'>
          <Row className='mb-5'>
            <Col sm={12} md={12}>
              <Form.Label>Select Property </Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
                required
              />
            </Col>
            </Row>
            {/* <Row className='mb-5'> 
            <Col sm={12} md={6}>
            <Form.Label>Select Address 1 </Form.Label>
              <Select
                defaultValue={selectedAddress1}
                // onChange={setSelectedOption}
                options={address1_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
            <Form.Label>Select Address 2 </Form.Label>
              <Select
                defaultValue={selectedAddress2}
                // onChange={setSelectedOption}
                options={address2_list}
                required
              />
            </Col>
          </Row>  */}

          <Row className='mb-5'>
      
             <Col sm={12} md={6}>
             <Form.Label>Select State </Form.Label>
              <Select
                defaultValue={selectedState}
                // onChange={setSelectedOption}
                options={state_list}
                required
              />
            </Col>

            <Col sm={12} md={6}>
            <Form.Label>Select City </Form.Label>
              <Select
                defaultValue={selectedCity}
                // onChange={setSelectedOption}
                options={city_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col sm={12} md={6}>
            <Form.Label>Select Zipcode </Form.Label>
              <Select
                defaultValue={selectedZipcode}
                // onChange={setSelectedOption}
                options={zipcode_list}
                required
              />
            </Col>
          </Row>
          

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-success me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Apply</span>
            </button>
           
          </div>

        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { PropertymanagementsListFilter }
