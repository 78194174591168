import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { PropertyListing } from '../modules/properties/PropertiesListing'
import { UnitListing } from '../modules/unit-details/UnitListing'
import { TenantsDetails } from '../modules/tenants-detals/TenantsDetails'
import { RentalPayment } from '../modules/rental-payment/RentalPayment'
import { TenantPortal } from '../modules/tenant-portal/TenantPortal'
import { Manager } from '../modules/manager/Manager'
import { MaintenancePeople } from '../modules/maintenance-people/MaintenancePeople'
import { Contacts } from '../modules/contacts/Contacts'
import { Visitors } from '../modules/visitors/Visitors'
import { Configurations } from '../modules/configurations/Configurations'
import { ViewTenantDetails } from '../modules/tenants-detals/ViewTenantDetails'
import { AddProperty } from '../modules/properties/AddProperty'
import { EditProperty } from '../modules/properties/EditProperty'
import { ViewPropertyDetails } from '../modules/properties/ViewProperty'
import { FloorListing } from '../modules/floor-management/FloorListing'
// import { Notifications } from '../modules/accounts/components/settings/cards/Notifications'
import { AddEditRentalForm } from '../modules/rental-payment/AddEditRental'
import { AddRentalForm } from '../modules/rental-payment/AddRentalForm'
import { EditRentalForm } from '../modules/rental-payment/EditRentalForm'
import { ViewRentalPaymentDetails } from '../modules/rental-payment/ViewRentalPaymentDetails'
import { ComplaintListing } from '../modules/complaints/ComplaintListing'
import { RequestCallBackListing } from '../modules/request-call-back/RequestCallBackListing'
import AddTenantsDetails from '../modules/tenants-detals/AddTenantDetails'
import EditTenantsDetails from '../modules/tenants-detals/EditTenantDetails'
import ViewInvoiceDetails from '../modules/rental-payment/ViewInvoiceDetails'
import Notifications from '../modules/notifications/Notifications'




// import { EditTenantModal } from '../modules/tenants-detals/tenants-list/tenant-edit-modal/EditTenantMOdal'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='properties' element={<PropertyListing />} />
        <Route path='unit-listing' element={<UnitListing />} />
        <Route path='tenants-details' element={<TenantsDetails />} />
        <Route path='add-tenants' element={<AddTenantsDetails />} />
        <Route path='edit-tenants' element={<EditTenantsDetails />} />
        <Route path='viewtenantdetails' element={<ViewTenantDetails />} />
        <Route path='rent-collections' element={<RentalPayment />} />
        <Route path='complaint-listing' element={<ComplaintListing />} />
        <Route path='tenant-portal' element={<TenantPortal />} />
        <Route path='manager' element={<Manager />} />
        <Route path='maintenance-people' element={<MaintenancePeople />} />
        <Route path='contacts' element={<Contacts />} />
        <Route path='visitors' element={<Visitors />} />
        <Route path='configurations' element={<Configurations />} />
        <Route path='addproperty' element={<AddProperty />} />
        <Route path='editproperty' element={<EditProperty />} />
        <Route path='viewproperty' element={<ViewPropertyDetails />} />
        <Route path='floor-listing' element={<FloorListing />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='notifications' element={<Notifications />} />
        <Route path='addedit-rental-form' element={<AddEditRentalForm />} />
        <Route path='add-rental-form' element={<AddRentalForm />} />
        <Route path='edit-rental-form' element={<EditRentalForm />} />
        <Route path='View-rental-form' element={<ViewRentalPaymentDetails />} />
        <Route path='request-call-back' element={<RequestCallBackListing />} />
        <Route path='invoice-details' element={<ViewInvoiceDetails />} />
        {/* <Route path='invoice-details' element={<Notifications />} /> */}
        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
