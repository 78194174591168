import {useListView} from '../../core/ListViewProvider'
import {TenantsListToolbar} from './TenantListToolbar'
import {TenantsListGrouping} from './TenantsListGrouping'
import {TenantsListSearchComponent} from './TenantsListSearchComponent'

const TenantsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <TenantsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TenantsListGrouping /> : <TenantsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TenantsListHeader}
