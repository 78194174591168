import {FC} from 'react'

type Props = {
  two_steps?: boolean
}

const RentalTwoStepsCell: FC<Props> = ({two_steps}) => (
  <> {two_steps && <div className='badge badge-danger fs-5 fw-bolder'>Due</div>}</>
)

export {RentalTwoStepsCell}
