// @ts-nocheck
import {Column} from 'react-table'
import {FloorInfoCell} from './FloorInfoCell'
import {FloorLastLoginCell} from './FloorLastLoginCell'
import {FloorTwoStepsCell} from './FloorTwoStepsCell'
import {FloorActionsCell} from './FloorActionsCell'
import {FloorSelectionCell} from './FloorSelectionCell'
import {FloorCustomHeader} from './FloorCustomHeader'
import {FloorSelectionHeader} from './FloorSelectionHeader'
import {Floor} from '../../core/_models'

const floorsColumns: ReadonlyArray<Column<Floor>> = [
  // {
  //   Header: (props) => <FloorSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <FloorSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <FloorCustomHeader tableProps={props} title='Floor Name' className='min-w-125px' />,
    accessor: 'floor_name',
    Cell: ({...props}) => "Floor Second"
  },
  
  {
    Header: (props) => (
      <FloorCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <FloorActionsCell id={props.data[props.row.index].id} />,
  },
]

export {floorsColumns}
