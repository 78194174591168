// @ts-nocheck
import {Column} from 'react-table'
import {TenantInfoCell} from './TenantInfoCell'
import {TenantLastLoginCell} from './TenantLastLoginCell'
import {TenantTwoStepsCell} from './TenantTwoStepsCell'
import {TenantActionsCell} from './TenantActionsCell'
import {TenantSelectionCell} from './TenantSelectionCell'
import {TenantCustomHeader} from './TenantCustomHeader'
import {TenantSelectionHeader} from './TenantSelectionHeader'
import {Tenant} from '../../core/_models'

const tenantsColumns: ReadonlyArray<Column<Tenant>> = [
  // {
  //   Header: (props) => <TenantSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <TenantSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <TenantCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    // Cell: ({...props}) => <TenantInfoCell tenant={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <TenantCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Mobile No' className='min-w-125px' />
    ),
    accessor: 'mobile',
    Cell: ({...props}) => 9887766550,
  },

  // {
  //   Header: (props) => (
  //     <TenantCustomHeader tableProps={props} title='Date Of Birth' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  //   // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },

  // {
  //   Header: (props) => (
  //     <TenantCustomHeader tableProps={props} title='Tenant Info' className='min-w-125px' />
  //   ),
  //   accessor: 'position',
  //   // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Family/ Individual' className='min-w-125px' />
    ),
    accessor: 'family_individual',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "Family",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Property Info' className='min-w-150px' />
    ),
    accessor: 'property_info',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "RentX Avenue",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Unit No' className='min-w-100px' />
    ),
    accessor: 'unit_no',
    Cell: ({...props}) => "D/501",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Tenure' className='min-w-125px' />
    ),
    accessor: 'tenure',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "Monthly",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Deposite Amount(QAR)' className='min-w-125px' />
    ),
    accessor: 'deposite_amount',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "25000",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Rent Amount(QAR)' className='min-w-125px' />
    ),
    accessor: 'rent_amount',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "11000",
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Deposite Due(QAR)' className='min-w-125px' />
    ),
    accessor: 'deposite_due',
    // Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
    Cell: ({...props}) => "00",
  },

  // {
  //   Header: (props) => (
  //     <TenantCustomHeader tableProps={props} title='Status' className='min-w-125px' />
  //   ),
  //   id: 'status',
  //    Cell: ({...props}) => <TenantLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <TenantTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },

  {
    Header: (props) => (
      <TenantCustomHeader tableProps={props} title='Actions' className='text-end min-w-150px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TenantActionsCell id={props.data[props.row.index].id} />,
  },
]

export {tenantsColumns}
