import {useListView} from '../../core/ListViewProvider'
import {ComplaintsListToolbar} from './ComplaintListToolbar'
import {ComplaintsListGrouping} from './ComplaintsListGrouping'
import {ComplaintsListSearchComponent} from './ComplaintsListSearchComponent'

const ComplaintsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <ComplaintsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ComplaintsListGrouping /> : <ComplaintsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ComplaintsListHeader}
