import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { initialRequest, Request } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { RequestsListLoading } from '../components/loading/RequestsListLoading'
import { createRequest, updateRequest } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


type Props = {
  isRequestLoading: boolean
  request: Request
}


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },

];

const floor_list = [
  { value: ' ', label: 'Select Floor' },
  { value: 'Select Floor One', label: 'Select Floor One' },
  { value: 'Select Floor Two', label: 'Select Floor Two' },
  { value: 'Select Floor Three', label: 'Select Floor Three' },
  { value: 'Select Floor Four', label: 'Select Floor Four' },
  { value: 'Select Floor Five', label: 'Select Floor Five' },

];


const editRequestSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const RequestEditModalForm: FC<Props> = ({ request, isRequestLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [requestForEdit] = useState<Request>({
    ...request,
    avatar: request.avatar || initialRequest.avatar,
    role: request.role || initialRequest.role,
    position: request.position || initialRequest.position,
    name: request.name || initialRequest.name,
    email: request.email || initialRequest.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  return (
    <>
      <form id='kt_modal_add_request_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_request_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_request_header'
          data-kt-scroll-wrappers='#kt_modal_add_request_scroll'
          data-kt-scroll-offset='300px'
        >



          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Select Property <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
                required
              />
            </Col>

            <Col sm={12} md={6}>
              <Form.Label>Select Floor <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedFloor}
                // onChange={setSelectedOption}
                options={floor_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-8'>
          <Col sm={12} md={6}>
          <Form.Group className="mb-8" >
                <Form.Label> Add Request No. <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <RequestsListLoading />} */}
    </>
  )
}

export { RequestEditModalForm }
