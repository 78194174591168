import { ID, Response } from "../../../../../_metronic/helpers"

// import {ID, Response} from '../../../../../../_metronic/helpers'
export type Propertymanagement = {
  id?: ID
  property_name?: string
  avatar?: string
  address1?: string
  address2?: string
  city?: string
  zipcode?: string
  country?: string
  state?: string
  position?: string
  role?: string
  name?: string
  email?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type PropertymanagementsQueryResponse = Response<Array<Propertymanagement>>

export const initialPropertymanagement: Propertymanagement = {
  avatar: 'avatars/300-6.jpg',
  //  position: 'Art Director',
  //  role: 'Administrator',
  name: '',
  email: '',
}
