import {useQuery} from 'react-query'
import {TenantEditModalForm} from './TenantEditModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getTenantById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const TenantEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: tenant,
    error,
  } = useQuery(
    `${QUERIES.TENANTS_LIST}-tenant-${itemIdForUpdate}`,
    () => {
      return getTenantById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  console.log("1222222222222",tenant)

  if (!itemIdForUpdate) {
    return <TenantEditModalForm isTenantLoading={false} tenant={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <TenantEditModalForm isTenantLoading={false} tenant={dataresponse} />
  }

  return null
}

export {TenantEditModalFormWrapper}
