import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {floorsColumns} from './columns/_columns'
import {Floor} from '../core/_models'
import {FloorsListLoading} from '../components/loading/FloorsListLoading'
import {FloorsListPagination} from '../components/pagination/FloorsListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'
import { defaultFloors } from '../../../../../_metronic/helpers/dataExamples'

const FloorsTable = () => {
   const floors = useQueryResponseData()
  // const floors = defaultFloors
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => floors, [floors])
  const columns = useMemo(() => floorsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_floors'
          className='table align-middle table-row-dashedfs-6 gy-5 dataTable no-footer '
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-4 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Floor>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FloorsListPagination />
      {isLoading && <FloorsListLoading />}
    </KTCardBody>
  )
}

export {FloorsTable}
