import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Request, RequestsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const REQUEST_URL = `${API_URL}/request`
const GET_REQUESTS_URL = `${API_URL}/users/query`

const getRequests = (query: string): Promise<RequestsQueryResponse> => {
  return axios
    .get(`${GET_REQUESTS_URL}?${query}`)
    .then((d: AxiosResponse<RequestsQueryResponse>) => d.data)
}

const getRequestById = (id: ID): Promise<Request | undefined> => {
  return axios
    .get(`${REQUEST_URL}/${id}`)
    .then((response: AxiosResponse<Response<Request>>) => response.data)
    .then((response: Response<Request>) => response.data)
}

const createRequest = (request: Request): Promise<Request | undefined> => {
  return axios
    .put(REQUEST_URL, request)
    .then((response: AxiosResponse<Response<Request>>) => response.data)
    .then((response: Response<Request>) => response.data)
}

const updateRequest = (request: Request): Promise<Request | undefined> => {
  return axios
    .post(`${REQUEST_URL}/${request.id}`, request)
    .then((response: AxiosResponse<Response<Request>>) => response.data)
    .then((response: Response<Request>) => response.data)
}

const deleteRequest = (requestId: ID): Promise<void> => {
  return axios.delete(`${REQUEST_URL}/${requestId}`).then(() => {})
}

const deleteSelectedRequests = (requestIds: Array<ID>): Promise<void> => {
  const requests = requestIds.map((id) => axios.delete(`${REQUEST_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getRequests, deleteRequest, deleteSelectedRequests, getRequestById, createRequest, updateRequest}
