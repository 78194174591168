import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import ImageUploading from 'react-images-uploading';
import { useNavigate } from "react-router-dom";
import AddEditTenantsDetails from "./AddEditTenantDetails";



const AddTenantsDetails = () => {

    const navigate = useNavigate()
    const navigateToTenantListing = () => {
        // 👇️ navigate to /contacts
        navigate('/tenants-details')
    }

    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Add Tenant Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToTenantListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary-light'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-12 mt-6">
         <AddEditTenantsDetails />
            </div>
        </>
    )
}

export default AddTenantsDetails;