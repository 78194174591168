import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {rentalsColumns} from './columns/_columns'
import {Rental} from '../core/_models'
import {RentalsListLoading} from '../components/loading/RentalsListLoading'
import {RentalsListPagination} from '../components/pagination/RentalsListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
// import {KTCardBody} from '../../../../../../_metronic/helpers'
import { defaultRentals } from '../../../../../_metronic/helpers/dataExamples'

const RentalsTable = () => {
   const rentals = useQueryResponseData()
  // const rentals = defaultRentals
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => rentals, [rentals])
  const columns = useMemo(() => rentalsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive' >
        <table
          id='kt_table_rentals'
          className='table align-middle table-row-dashedfs-6 gy-5 dataTable no-footer rental-payment-listing '
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-4 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Rental>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RentalsListPagination />
      {isLoading && <RentalsListLoading />}
    </KTCardBody>
  )
}

export {RentalsTable}
