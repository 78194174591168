import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { toAbsoluteUrl } from "../../../_metronic/helpers";



const ViewPropertyDetails = () => {

    const navigate = useNavigate()
    const navigateToPropertyListing = () => {
        // 👇️ navigate to /contacts
        navigate('/properties')
    }
    return (

        <>
            <Row className="row">
                <Col md={8} sm={12}><h1> View Property Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToPropertyListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-8  mt-6  m-auto h-100">
                <h2 className="text-left text-primary py-3"> RentX Avenue </h2>
                <div className="fs-3 text-black text-start fw-normal py-2 pb-8"> Most properties hold current or potential monetary value and are therefore considered to be assets. But properties can simultaneously be liabilities in some situations. Examples of property, which may be tangible or intangible, include automotive vehicles, industrial equipment, furniture, and real estate—the last of which is often referred to as "real property."</div>

                

                <div className="row py-8">
                    <div className="col-md-12 col-sm-12 mb-8">
                        <h3 className="text-primary"> Property Pictures </h3>
                        
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-lg-4 col-xl-3">
                                <Zoom >
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                        className="mb-5"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"

                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                        className="mb-5"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                    />

                                </Zoom>
                            </div>
                            <div className="col-md-6 col-sm-12  col-lg-4 col-xl-3">
                                <Zoom>
                                    <img
                                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                        src={toAbsoluteUrl('/media/misc/property-img.jpg')}
                                        width="100%"
                                    />

                                </Zoom>
                            </div>

                        </div>
                        {/* <img src={toAbsoluteUrl('/media/misc/property-img.jpg')} className='rounded mw-100' alt='' /> */}
                    </div>
                </div>
                
                <div className="row py-8">
                    <h3 className="text-primary"> View Location </h3>
                    

                    <div className="col-md-12 col-sm-12 mb-4">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.3664890380546!2d72.50477017603627!3d23.010312116784288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84bab9891d85%3A0x3b38441f63bc3def!2sSufalam%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1708930542644!5m2!1sen!2sin" width="100%" height="364" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" ></iframe>
                    </div>

                    <div className="col-md-12 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal d-block">Coordinates:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</div>
                    </div>
                </div>

                

                <div className="row py-8">
                    <h3 className="text-primary"> Address Information </h3>
                    <div className="separator separator-dashed mb-2"></div>

                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal d-block">Address 1:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal d-block">Address 2:</span> Sed ut perspiciatis unde omnis iste natus error sit voluptatem</div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Country:</span> Qatar </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">State:</span> State </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">City:</span> Doha </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="fs-3 text-black text-start fw-bold py-2"> <span className="fw-normal">Zipcode :</span> 11596 </div>
                    </div>


                </div>
            </div>


        </>

    )

}


export { ViewPropertyDetails }