import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { initialPropertymanagement, Propertymanagement } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { PropertymanagementsListLoading } from '../components/loading/PropertymanagementsListLoading'
import { createPropertymanagement, updatePropertymanagement } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';


type Props = {
  isPropertymanagementLoading: boolean
  propertymanagement: Propertymanagement
}


const property_list = [
  { value: ' ', label: 'Select Property' },
  { value: 'Rentx Avenue', label: 'Rentex Avenue' },
  { value: 'Rentx Heights', label: 'Rentex Heights' },
  { value: 'Rentx Villa', label: 'Rentx Villa' },
  { value: 'RentX Appartment', label: 'RentX Appartment' },
  { value: 'Rentx Enclave', label: 'Rentx Enclave' },

];

const appartment_list = [
  { value: ' ', label: 'Select Appartment' },
  { value: 'Select Appartment One', label: 'Select Appartment One' },
  { value: 'Select Appartment Two', label: 'Select Appartment Two' },
  { value: 'Select Appartment Three', label: 'Select Appartment Three' },
  { value: 'Select Appartment Four', label: 'Select Appartment Four' },
  { value: 'Select Appartment Five', label: 'Select Appartment Five' },

];


const editPropertymanagementSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const PropertymanagementEditModalForm: FC<Props> = ({ propertymanagement, isPropertymanagementLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [propertymanagementForEdit] = useState<Propertymanagement>({
    ...propertymanagement,
    avatar: propertymanagement.avatar || initialPropertymanagement.avatar,
    role: propertymanagement.role || initialPropertymanagement.role,
    position: propertymanagement.position || initialPropertymanagement.position,
    name: propertymanagement.name || initialPropertymanagement.name,
    email: propertymanagement.email || initialPropertymanagement.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedAppartment, setSelectedAppartment] = useState(null);

  return (
    <>
      <form id='kt_modal_add_propertymanagement_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
          id='kt_modal_add_propertymanagement_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_propertymanagement_header'
          data-kt-scroll-wrappers='#kt_modal_add_propertymanagement_scroll'
          data-kt-scroll-offset='300px'
        >

          <h3 className=' px-5 py-3 mb-8' > Personal Details </h3>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="Text" className='form-control form-control-solid' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Email address <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="email" className='form-control form-control-solid' placeholder=" " required />
              </Form.Group>
            </Col>

          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Mobile No <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="tel" className='form-control form-control-solid' placeholder=" " required />
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type="date" className='form-control form-control-solid' placeholder=" "  />
              </Form.Group>
            </Col>
          </Row>


          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3" >
                <Form.Label>Propertymanagement Info <span className='fs-6 text-danger'> * </span></Form.Label>
                <Row>
                  <Col className='d-inline-block'>
                    <Form.Check // prettier-ignore
                      type="radio"
                      id="individual"
                      label="Individual"
                      name="group1"
                      className='d-inline-block me-10'
                  
                    />
                    <Form.Check
                      type="radio"
                      label="Family"
                      id="family"
                      name="group1"
                      className='d-inline-block'
                      
                    />
                  </Col>

                </Row>
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-7'></div>

          <h3 className=' px-5 py-3 mb-8' > Property Details </h3>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Select Property <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedProperty}
                // onChange={setSelectedOption}
                options={property_list}
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Select Appartment <span className='fs-6 text-danger'> * </span></Form.Label>
              <Select
                defaultValue={selectedAppartment}
                // onChange={setSelectedOption}
                options={appartment_list}
                required
              />
            </Col>
          </Row>

          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Label>Status</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Active"
                checked
              />
            </Col>
          </Row>

          <div className='separator mb-4'></div>

          <div className='text-end pt-5'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {/* { <PropertymanagementsListLoading />} */}
    </>
  )
}

export { PropertymanagementEditModalForm }
