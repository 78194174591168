// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {RentalsListFilter} from './RentalsListFilter'

const RentalsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddRentalModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddRentalForm = () => {
    // 👇️ navigate to /
    navigate(`/add-rental-form`)
  }


  return (
    <div className='d-flex justify-content-end' data-kt-rental-table-toolbar='base'>
      <RentalsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add rental */}
      <button type='button' className='btn btn-primary-light' onClick={navigateAddRentalForm}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Rental
      </button>
      {/* end::Add rental */}
    </div>
  )
}

export {RentalsListToolbar}
