// @ts-nocheck
import {Column} from 'react-table'
import {ComplaintInfoCell} from './ComplaintInfoCell'
import {ComplaintLastLoginCell} from './ComplaintLastLoginCell'
import {ComplaintTwoStepsCell} from './ComplaintTwoStepsCell'
import {ComplaintActionsCell} from './ComplaintActionsCell'
import {ComplaintSelectionCell} from './ComplaintSelectionCell'
import {ComplaintCustomHeader} from './ComplaintCustomHeader'
import {ComplaintSelectionHeader} from './ComplaintSelectionHeader'
import {Complaint} from '../../core/_models'

const complaintsColumns: ReadonlyArray<Column<Complaint>> = [
  // {
  //   Header: (props) => <ComplaintSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ComplaintSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <ComplaintCustomHeader tableProps={props} title='Date of Reporting' className='min-w-125px' />,
    accessor: 'reporting_date',
    Cell: ({...props}) => "18th Jan 2024"
    // Cell: ({...props}) => <ComplaintInfoCell complaint={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ComplaintCustomHeader tableProps={props} title='Unit Number' className='min-w-125px' />,
    accessor: 'unit_number',
    Cell: ({...props}) => "D/501"
  },

  {
    Header: (props) => (
      <ComplaintCustomHeader tableProps={props} title='Complaint About' className='min-w-125px' />
    ),
    accessor: 'complaint_about',
    Cell: ({...props}) => "Maitenance",
  },

  {
    Header: (props) => (
      <ComplaintCustomHeader tableProps={props} title='Complaint Raised By' className='min-w-125px' />
    ),
    accessor: 'complaint_raised',
    Cell: ({...props}) => "aiyubpanara@gmail.com",
  },

  {
    Header: (props) => (
      <ComplaintCustomHeader tableProps={props} title='Status' className='min-w-150px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ComplaintTwoStepsCell  />,
  },


  {
    Header: (props) => (
      <ComplaintCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ComplaintActionsCell id={props.data[props.row.index].id} />,
  },
]

export {complaintsColumns}
