import React, { useState } from "react";
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import ImageUploading from 'react-images-uploading';
import { useNavigate } from "react-router-dom";

const property_list = [
    { value: ' ', label: 'Select Property' },
    { value: 'Rentx Avenue', label: 'Rentex Avenue' },
    { value: 'Rentx Heights', label: 'Rentex Heights' },
    { value: 'Rentx Villa', label: 'Rentx Villa' },
    { value: 'RentX Appartment', label: 'RentX Appartment' },
    { value: 'Rentx Enclave', label: 'Rentx Enclave' },


];

const unit_list = [
    { value: ' ', label: 'Select Unit No' },
    { value: 'D/501', label: 'D/501' },
    { value: 'D/502', label: 'D/502' },
    { value: 'D/503', label: 'D/503' },
    { value: 'D/504', label: 'D/504' },
    { value: 'D/505', label: 'D/505' },

];


const AddEditTenantsDetails = () => {

    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList: any, addUpdateIndex: any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };


    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);


    const navigate = useNavigate()
    const navigateToTenantListing = () => {
        // 👇️ navigate to /contacts
        navigate('/tenants-details')
    }

    return (

        <>



            <form id='kt_modal_add_tenant_form' className='form' >
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7 Krunal'
                    id='kt_modal_add_tenant_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_tenant_header'
                    data-kt-scroll-wrappers='#kt_modal_add_tenant_scroll'
                    data-kt-scroll-offset='300px'
                >

                    <h3 className=' px-0 py-3 mb-8' > Personal Details </h3>

                    <Row className='mb-8'>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3 " >
                                <Form.Label>Tenant Name <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="Text" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email address <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="email" className='form-control form-control-solid' placeholder=" " required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mobile No <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="tel" className='form-control form-control-solid' placeholder=" " required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="date" className='form-control form-control-solid' placeholder=" " />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-8'>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Contract Start Date <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="tel" className='form-control form-control-solid' placeholder=" " required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Contract End Date</Form.Label>
                                <Form.Control type="date" className='form-control form-control-solid' placeholder=" " />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <div className="App image upload section">
                                <Form.Label> Upload Photo </Form.Label>
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                >
                                    {({

                                        onImageUpload,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <button
                                                className="py-3 px-3 fs-4"
                                                style={isDragging ? { color: 'red' } : undefined}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                Click or Drop here
                                            </button>
                                            &nbsp;
                                            {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}

                                        </div>
                                    )}
                                </ImageUploading>

                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={3}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Tenant Info <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Row>
                                    <Col className='d-inline-block'>
                                        <Form.Check // prettier-ignore
                                            type="radio"
                                            id="individual"
                                            label="Individual"
                                            name="group1"
                                            className='d-inline-block me-10 mt-0'

                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Family"
                                            id="family"
                                            name="group1"
                                            className='d-inline-block mt-0'

                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className=''>
                            <Col>
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpdate,
                                        onImageRemove,
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">

                                            &nbsp;
                                            {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
                                            <div className="d-flex flex-wrap">
                                                {imageList.map((image, index) => (

                                                    <div key={index} className="image-item my-10 mx-5">
                                                        <img src={image['data_url']} alt="" style={{ maxWidth: "100%" }} />
                                                        <div className="image-item__btn-wrapper text-center">
                                                            {/* <button onClick={() => onImageUpdate(index)} className="me-2 px-1 update bg-light-orange text-orange"> Update </button> */}
                                                            <button onClick={() => onImageRemove(index)} className="px-1 remove"> X </button>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>

                            </Col>
                        </Row>

                    <div className='separator mb-7'></div>

                    <h3 className=' px-0 py-3 mb-8' > Property Details </h3>

                    <Row className='mb-8'>
                        <Col sm={12} md={6} lg={4} >
                            <Form.Label>Select Property <span className='fs-6 text-danger'> * </span></Form.Label>
                            <Select
                                defaultValue={selectedProperty}
                                // onChange={setSelectedOption}
                                options={property_list}
                                required
                            />
                        </Col>
                        <Col sm={12} md={6}  lg={4}>
                            <Form.Label>Select Unit <span className='fs-6 text-danger'> * </span></Form.Label>
                            <Select
                                defaultValue={selectedProperty}
                                // onChange={setSelectedOption}
                                options={unit_list}
                                required
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Owner Name <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>
                    </Row>



                    <div className='separator mb-4'></div>


                    <h3 className='px-0 py-3 mb-8'> Payment Details </h3>

                    <Row className='mb-8'>
                        <Col sm={12} md={6} lg={4}>
                            <Row>
                                <Form.Label> Tenure <span className='fs-6 text-danger'> * </span></Form.Label>
                            </Row>
                            <Col className='d-inline-block mt-3'>
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="dd"
                                    label="Monthly"
                                    name="tenure"
                                    className='d-inline-block me-10'

                                />
                                <Form.Check
                                    type="radio"
                                    label="Yearly"
                                    id="cash"
                                    name="tenure"
                                    className='d-inline-block me-10'

                                />

                            </Col>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Rent Amount <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Deposite Amount  <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-8'>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Label> Utility  </Form.Label>
                            <div className="row mx-0 justify-content-cetween w-100">
                                <div  className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Water Bill
                                    </label>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Electric Bill
                                    </label>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Gas Bill
                                    </label>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Security Bill
                                    </label>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Utility Bill
                                    </label>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-4 form-check">
                                    <input className="form-check-input mt-0" type="checkbox" value="" id="flexCheckChecked" />
                                    <label className="form-check-label  me-6 mb-3" >
                                        Other Bill
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Deposite Due <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="text" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={6}  lg={4}>
                            <Form.Label className="d-block"> Mode Of Payment <span className='fs-6 text-danger'> * </span></Form.Label>

                            <Col className='d-inline-block mt-3'>
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="dd"
                                    label="DD"
                                    name="group1"
                                    className='d-inline-block me-10'

                                />
                                <Form.Check
                                    type="radio"
                                    label="Cash"
                                    id="cash"
                                    name="group1"
                                    className='d-inline-block me-10'

                                />
                                <Form.Check
                                    type="radio"
                                    label="Cheque"
                                    id="cheque"
                                    name="group1"
                                    className='d-inline-block me-10'

                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row className='mb-8'>

                       
                       
                    </Row>
                    <Row className='mb-8'>
                        <Col sm={12} md={6}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Upload Document </Form.Label>
                                <Form.Control type="file" className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Label>Status</Form.Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="Active"
                                checked
                            />
                        </Col>
                    </Row>
                    <div className='text-end pt-5'>

                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                            data-kt-users-modal-action='submit'
                        >
                            <span className='indicator-label'>Submit</span>
                        </button>

                        <button
                            type='reset'
                            className='btn btn-light-danger '
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </button>
                    </div>

                </div>
            </form>

        </>
    )
}

export default AddEditTenantsDetails;