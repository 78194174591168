import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PropertymanagementsListHeader} from './components/header/PropertymanagementsListHeader'
import {PropertymanagementsTable} from './table/PropertymanagementsTable'
import {PropertymanagementEditModal} from './propertymanagement-edit-modal/PropertymanagementEditModal'
import { KTCard } from '../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const PropertymanagementsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <PropertymanagementsListHeader />
        <PropertymanagementsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <PropertymanagementEditModal />}
    </>
  )
}

const PropertymanagementsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PropertymanagementsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PropertymanagementsListWrapper}
