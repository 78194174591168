import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Rental, RentalsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const RENTAL_URL = `${API_URL}/rental`
const GET_RENTALS_URL = `${API_URL}/users/query`

const getRentals = (query: string): Promise<RentalsQueryResponse> => {
  return axios
    .get(`${GET_RENTALS_URL}?${query}`)
    .then((d: AxiosResponse<RentalsQueryResponse>) => d.data)
}

const getRentalById = (id: ID): Promise<Rental | undefined> => {
  return axios
    .get(`${RENTAL_URL}/${id}`)
    .then((response: AxiosResponse<Response<Rental>>) => response.data)
    .then((response: Response<Rental>) => response.data)
}

const createRental = (rental: Rental): Promise<Rental | undefined> => {
  return axios
    .put(RENTAL_URL, rental)
    .then((response: AxiosResponse<Response<Rental>>) => response.data)
    .then((response: Response<Rental>) => response.data)
}

const updateRental = (rental: Rental): Promise<Rental | undefined> => {
  return axios
    .post(`${RENTAL_URL}/${rental.id}`, rental)
    .then((response: AxiosResponse<Response<Rental>>) => response.data)
    .then((response: Response<Rental>) => response.data)
}

const deleteRental = (rentalId: ID): Promise<void> => {
  return axios.delete(`${RENTAL_URL}/${rentalId}`).then(() => {})
}

const deleteSelectedRentals = (rentalIds: Array<ID>): Promise<void> => {
  const requests = rentalIds.map((id) => axios.delete(`${RENTAL_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getRentals, deleteRental, deleteSelectedRentals, getRentalById, createRental, updateRental}
